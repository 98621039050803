/**
 * BASIC FLEXBOX GRID
 *
 * flex shorthand
 * flex-grow: 0
 * flex-shrink: 1
 + flex-basis: auto
 */


/* settings
 ******************************************************************************/
 @use "sass:math";

$grid-columns: 12;
 $grid-spacing: $main-content-spacing;

 /**
  * generate columns mixin
  * @colums: number
  * @size-variant: string
  */

 @mixin gridColumns($columns, $size-variant: "") {
   @for $i from 1 through $columns {
     .col#{ if($size-variant == "", unquote("-"), unquote("-#{$size-variant}-")) }#{$i} {
       flex-basis: math.div(100%, math.div($columns, $i));
       max-width:  math.div(100%, math.div($columns, $i));
     }
   }
 }

 /* component code
  ******************************************************************************/
 .grid-container,
 .grid-row {
   box-sizing: border-box;
 }

 .grid-container {
   margin-right: auto;
   margin-left: auto;
   padding-right: $grid-spacing;
   padding-left: $grid-spacing;
   @if var(--enable-light-skin){
    border: 1px solid $color-gray-light-c;
    border-top-width: 0;
   }
 }

 .grid-row {
   display: flex;
   flex: 0 1 auto;
   flex-direction: row;
   flex-wrap: wrap;
   margin-right: -($grid-spacing*0.5);
   margin-left: -($grid-spacing*0.5);
 }

 .col,
 [class*="col-"] {
   padding-left: ($grid-spacing*0.5);
   padding-right: ($grid-spacing*0.5);
   flex: 1;
 }


 // default colums
 @include gridColumns($grid-columns);

 // extra large breakpoint columns
 @include mediaQuery(xl) {
   @include gridColumns($grid-columns, "xl");
 }

 // large breakpoint columns
 @include mediaQuery(lg) {
   @include gridColumns($grid-columns, "lg");
 }

 // medium breakpoint columns
 @include mediaQuery(md) {
   @include gridColumns($grid-columns, "md");
 }

 // small breakpoint columns
 @include mediaQuery(sm) {
   @include gridColumns($grid-columns, "sm");
 }
