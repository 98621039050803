/**
 * bredcrumbs styles
 */


 /*
 ************************************************************************ breadcrumbs */
 $component-breadcrumbs-text-color:  if( var(--enable-light-skin), var(--color-brand-text-accent-light-50), $color-gray-light-b);

 /*
 ************************************************************************ figure icon */
 $component-figure-icon-bg:     if( var(--enable-light-skin), $color-brand-accent-light-800, $color-gray-light-b);
 $component-figure-icon-border: $color-brand-accent-900;
 $component-figure-icon-active: $color-medium-purple;

 /*
 ************************************************************************ image-conteinter */
 $component-image-container-bg:     if( var(--enable-light-skin), $color-brand-accent-light-800, $color-gray-light-b);
 $component-image-container-border: if( var(--enable-light-skin), $color-brand-accent-light-800, $color-gray-light-b);

 /*
 ************************************************************************ line-separator */
 $component-line-separator-before-bg:  if( var(--enable-light-skin), $color-white, var(--color-brand-accent));
 $component-line-separator-bg:         if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
 $component-second-bg-color:           $color-brand-accent-900;
  /*
 ************************************************************************ to inputs */
 $input-disabled-bg-color:              if( var(--enable-light-skin), var(--color-brand-accent-light), transparent);
 $input-disabled-border-color:          if( var(--enable-light-skin), $color-gray-light-b, $color-gray-cod);

 $input-text-color:                     if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);

 $circle-icon-btn-color:                if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
 $circle-icon-btn-border-color:         if( var(--enable-light-skin), $color-gray-light-c , $color-brand-accent-900);
 $circle-icon-btn-bg-hover-color:       if( var(--enable-light-skin), $color-gray-light-c , rgba(0,0,0,.12));

/*
 ************************************************************** dropdown menu */
 $dropdown-menu-bg: if( var(--enable-light-skin), $color-white, var(--color-brand-accent));

/*
 ************************************************************** simple tab */
 $simple-tab-color: if( var(--enable-light-skin), var(--color-brand-text-accent-light-50), $color-white);

 /*
 ************************************************************** rounded switch */
 $rounded-switch-bg:              if( var(--enable-light-skin), var(--color-brand-accent-light), $color-brand-accent-900);
 $rounded-switch-text:            if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
 $rounded-switch-border-color:    if( var(--enable-light-skin), var(--color-brand-accent-light), transparent);
