/*
 * SETTINGS FOR MAIN COMPONENTS
 */


/* toolbar settings
 ******************************************************************************/
  $toolbar-height:                  50px;
  $toolbar-user-menu-width:         216px;

  $toolbar-logo-shadow-dark:        inset -1px 0px 5px 0px rgba(0, 0, 0, 0.45);
  $toolbar-logo-shadow-light:       inset -1.5px 0px 0px 0px rgba(255, 255, 255, 0.45);
  $toolbar-bg-dark-shadow:          2px 2px 4px 0 rgba(0, 0, 0, 0.45);
  $toolbar-items-default-color:     $color-white;
  $color-vivaorganica:              #fddc3a;
  $toolbar-background-dark-theme:   linear-gradient(45deg, $color-vivaorganica 0%, $color-brand-primary 100%);
  $toolbar-background-light-theme:  $color-vivaorganica;
  $toolbar-background-color:        if( var(--enable-light-skin), $toolbar-background-light-theme, $toolbar-background-dark-theme );
  $toolbar-background-shadow:       if( var(--enable-light-skin), none, $toolbar-bg-dark-shadow);

  $toolbar-logo-shadow:             if( var(--enable-light-skin), $toolbar-logo-shadow-light, $toolbar-logo-shadow-dark );

  /* sidenav settings
   ******************************************************************************/
  $sidenav-default-width:           306px;
  $sidenav-collapsed-hover-width:   200px;
  $sidenav-collapsed-width:         50px;
  $sidenav-background-color:        if( var(--enable-light-skin), var(--color-brand-accent-light), var(--color-brand-accent) );
  $sidenav-transition-time:         .2s;
  $sidenav-item-height:             50px;
  $sidenav-item-font-size:          14px;
  $sidenav-first-item-margin:       65px;
  $sidenav-sub-list-bg:             if( var(--enable-light-skin), var(--color-brand-accent-light), darken(var(--color-brand-accent), 5%) );

  $sidenav-svg-color:               if( var(--enable-light-skin), var(--color-brand-text-accent-light), $color-white );
  $sidenav-svg-color-shadow:        if( var(--enable-light-skin), var(--color-brand-accent-light), $color-gray-dark);
  $sidenav-svg-hover-color:         if( var(--enable-light-skin), var(--color-brand-primary), $color-white);
  $sidenav-svg-transparence:        if( var(--enable-light-skin), var(--color-brand-accent-light), var(--color-brand-accent));

  /* sidenav items settings
   ******************************************************************************/
  $sidenav-items-default-color:     if( var(--enable-light-skin), var(--color-brand-text-accent-light), $color-gray-light-b);
  $sidenav-items-active-color:      $color-white;
  $sidenav-items-hover-color:       if( var(--enable-light-skin), var(--color-brand-primary), $color-white);
