/*
**
* panel header nav
**
*/

//variables

.panel-header {
  background-color: $panel-tab-label-bg;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  &__label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    color: $panel-tab-title;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__label.panel-label-active {
    color: $panel-tab-title-active;
  }
}
