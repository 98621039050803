/*
 * ANGULAR MATERIAL CUSTOM THEME
 * documentation: https://material.angular.io/guide/theming
 */
@use '@angular/material' as mat;


// angular core

// angular material core mixin
@include mat.core();

// Define the default theme
$vivaorganica-primary: mat.define-palette($primary-color-palette, 500, 300, 700); // primary color
$vivaorganica-accent:  mat.define-palette(if($enable-light-theme, $accent-secondary-color-palette, $accent-default-color-palette), 500, A200, A400); // accent color
$vivaorganica-warn:    mat.define-palette($warn-color-palette); // warn color

// Create the theme object (a Sass map containing all of the palettes).
$vivaorganica-theme: mat.define-light-theme($vivaorganica-primary, $vivaorganica-accent, $vivaorganica-warn);

// Include theme styles for core and each component used in your app.
@include mat.all-component-themes($vivaorganica-theme);
