/*
 * SETTINGS FOR CUSTOM MATERIAL COMPONENTS
 */


  $mat-control-border-color: rgba(61,61,61,0.6);
  $mat-control-shadow-color:  $color-black;

  /* mat-form field
  *************************************************************************************************/
  $mat-input-padding-left:          10px;

  $mat-input-bg-color:              if( var(--enable-light-skin), $color-white, $color-brand-accent-900);
  $mat-input-shadow:                0 0 4px 0;
  $mat-input-disabled-bg-color:     if( var(--enable-light-skin), var(--color-brand-accent-light), $color-gray-cod);
  $mat-input-border-color:          if( var(--enable-light-skin), $color-gray-light-b, $color-gray-cod);

  $mat-input-text-color:            if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
  $mat-input-second-text-color:     $color-white-60;

  $mat-input-shadow-color:          $color-gray-cod;

  /* mat-checkbox
  *************************************************************************************************/
  $mat-checkbox-first-border-color:     if( var(--enable-light-skin), $color-gray-theme-dark, $color-brand-accent-900);
  $mat-checkbox-second-border-color:    $color-white-60;
  $mat-checkbox-checked-color:          if( var(--enable-light-skin), $color-white, $color-brand-accent-900);

  /* select component
  *************************************************************************************************/
  $mat-select-bg:                   if( var(--enable-light-skin), var(--color-brand-accent-light), $color-gray-cod);
  $mat-select-bg-active:            if( var(--enable-light-skin), $color-white, $color-gray-cod);
  $mat-select-text-color-active:    if( var(--enable-light-skin), $color-gray-theme-dark ,$color-white);
  $mat-select-text-color-selected:  if( var(--enable-light-skin), var(--color-brand-primary) ,$color-white);
  $mat-select-bg-selected:          if( var(--enable-light-skin), rgba(0, 0, 0, 0.04), $color-gray-cod);
  $mat-select-border-color:         if( var(--enable-light-skin), $color-gray-light-b, $color-gray-cod);
  $mat-select-placeholder-color:    if( var(--enable-light-skin), var(--color-brand-text-accent-light-50),$color-white-60);
  $mat-select-second-border-color:  $color-brand-accent-900;

  $mat-select-text-color:           if( var(--enable-light-skin), $color-gray-theme-dark ,$color-white);
  $mat-select-second-text-color:    if( var(--enable-light-skin), $color-gray-theme-dark ,$color-white-60);

  /* mat option
  *************************************************************************************************/
  $mat-select-bg-active-disabled:          var(--color-brand-primary);
  $mat-select-text-color-active-disabled:  if( var(--enable-light-skin), $color-white, $color-gray-theme-dark);

  /* datepicker component
  *************************************************************************************************/
  $mat-datepicker-text-color:             if( var(--enable-light-skin), $color-gray-theme-dark ,$color-white);
  $mat-datepicker-content-color:          if( var(--enable-light-skin), var(--color-brand-accent-light), $color-brand-accent );
  $mat-datepicker-calendar-selected:      var(--color-brand-primary);
  $mat-datepicker-calendar-text-color-selected: $color-white;
  $mat-datepicker-calendar-first-color:   if( var(--enable-light-skin), var(--color-brand-primary) , rgba(255, 255, 255, 0.54));
  $mat-datepicker-calendar-second-color:  if( var(--enable-light-skin), var(--color-brand-text-accent-light-50) ,rgba(255, 255, 255, .38));
  $mat-datepicker-calendar-third-color:   if( var(--enable-light-skin), $color-gray-theme-dark ,rgba(255, 255, 255, 0.87));

  /* button component
  *************************************************************************************************/
  $mat-button-accent-bg-color: $color-brand-accent-400;

  /* rounded-btn
  *************************************************************************************************/
  $component-rounded-btn-border: if( var(--enable-light-skin), $color-gray-light-c,$color-brand-accent-900 );

  /* button toggle component
  *************************************************************************************************/
  $mat-button-toggle-bg:                  if( var(--enable-light-skin), var(--color-brand-accent-light), $color-brand-accent );
  $mat-button-toggle-color-text:          if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
  $mat-button-toggle-color-text-active:   $color-white;

  $mat-button-toggle-border:              if( var(--enable-light-skin), $color-gray-light-c, $color-gray-cod );
  $mat-button-toggle-border-active:       if( var(--enable-light-skin), $color-gray-light-c, $jacksons-purple );

  /* mat-chip component
  *************************************************************************************************/
  $mat-chip-text-color: $color-white;

  /* list component
  *************************************************************************************************/
  $mat-list-item-height:          38px;
  $mat-list-item-color:           $color-gray-dark;

  /* table component
  *************************************************************************************************/
  $mat-table-bg-color:            $color-gray-dark;

  /* mat-expansion-panel
  *************************************************************************************************/
  $mat-expansion-panel-border-color:        $color-brand-accent-900;
  $mat-expansion-panel-text-color:          $color-white;

  /* mat-dialog
  *************************************************************************************************/
  $mat-dialog-overlay-bg-color:         rgba(#181818, .5);
  $mat-dialog-shadow-color:             rgba($color-black, 0.5);
  $mat-dialog-button-border-color:      $color-gray-cod;

  /* mat-tab
  *************************************************************************************************/
  $mat-tab-label-icon-color:         if( var(--enable-light-skin), var(--color-brand-primary), $color-gray-cod);
