/*
   Custom component: rounded-switch styles

*/

// variables
$font-size-labels: 10px;
$padding-label: 5px;

.rounded-switch {
  display: inline-block;
  font-size: $font-size-labels;
  height: 22px;
  min-width: 159px;
  position: relative;
  text-transform: uppercase;

  /* Hide default HTML checkbox */
  input { display:none; }

  /* The slider */
  .slider {
    @if var(--enable-light-skin) {
      border: 1px solid $rounded-switch-border-color;
    }
    background-color: $rounded-switch-bg;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    transition: .4s;
    top: 0;
  }

  .slider-container {
    background-color: var(--color-brand-primary);
    @if var(--enable-light-skin){
      color: $color-white;
    }
    border-radius: 11px;
    height: 100%;
    padding: $padding-label;
    position: absolute;
    transition: .4s;
  }

  .slider-labels {
    color: $rounded-switch-text;
    display: flex;
  }

  .slider-container,
  .slider-labels span {
    padding: $padding-label;
    text-align: center;
    width: 50%;
  }
}

// When input is checked
.rounded-switch.checked .slider-container {
  transform: translateX(100%);
}

.rounded-switch.checked .slider-labels {
  span:first-child{
    opacity: 1;
  }
  span:last-child {
    opacity: 0;
  }
}

// When input is unchecked
.rounded-switch.no-checked .slider-labels span:first-child {
    opacity: 0;
}

