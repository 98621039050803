
.dashboard__totals {
  display: flex;
}

.dashboard__totals-item {
  align-items: center;
  flex-direction: column;
}

.dashboard__totals-table th {
  font-weight: 900;
}
