/*
* buttons theme settings
*/

/*
************************************************************************* primary styles */
$button-detail-dark-theme-color-primary:                 var(--color-brand-primary);


/*
************************************************************************* accent styles */
$button-detail-dark-theme-color-accent:                   $color-brand-accent-900;
$button-detail-light-theme-color-accent:                  $color-gray-light-c;
$button-detail-light-theme-hover-color-accent:            rgba(239, 240, 243, .3);

$button-new-form-dark-theme-color-accent:                 $color-brand-accent-900;

$button-cancel-form-dark-theme-color-accent:              $color-gray-dark;
$button-cancel-form-dark-theme-border-color-accent:       $color-brand-accent-900;

/*
************************************************************************* warn styles */

$button-detail-dark-theme-color-warn:                     $color-brand-warn;
$button-remove-light-theme-border-color-warn:             $color-gray-light-c;
$button-remove-dark-theme-color-warn:                     $color-brand-warn;
