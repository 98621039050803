/*
 *  CATALOG BUTTONS STYLES
 */

.catalog__image-button {
  background-color: $catalog-button-bg-color;
  width: 119px;
  
  .mat-icon {
    color: $catalog-button-color;
    font-size: 14px;
    height: 18px;
    opacity: .8;
    width: 14px;
  }
}