/*
 * GLOBAL BODY ELEMENT
 */



// body settings
$main-content-spacing:    30px;

$body-size-font:          13px;
$body-roboto-font:        'Roboto', sans-serif;
$body-base-color:         if( var(--enable-light-skin), $color-gray-theme-dark, $color-gray-light-b);
$body-background-color:   if( var(--enable-light-skin), $color-white, $color-gray-dark);


// scroll
$body-scroll-bg-thumb-color:    if( var(--enable-light-skin), $color-gray-light-c, rgba(255,255,255,.4));
$body-scroll-bg-track-color:    if( var(--enable-light-skin), var(--color-brand-accent-light), transparent);

// dragable
$body-default-dragable-bg-color:      if( var(--enable-light-skin), var(--color-brand-accent-light), #2c2c2c);
$body-default-dragable-opacity:       0.7;
