/**
 * MAT-BUTTON-TOGGLE
 */

// mat-button-toggle-group.mat-button-toggle-group {
//   box-shadow: none;
// }

.mat-button-toggle {
  // checked state
  &.mat-button-toggle-checked {
    color:  $tuna;
    background-color: var(--color-brand-primary);
    border-color: $mat-button-toggle-border-active;
    font-weight: bold;
  }
}
