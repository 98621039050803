/*
 * BASE RESET RULES
 */


 *,
 *::after,
 *::before {
  box-sizing: border-box; // How css, should calculate the total width and height of an element
}
