/*
 * GLOBAL NATIVE CSS SETTINGS
 */


/* native css variables
 ******************************************************************************/
:root {
  --enable-light-skin:                 #{$enable-light-theme}; // var(--enable-light-accent-skin)
  --color-brand-primary:               #{$color-brand-primary};

  --color-brand-accent:                #{$color-brand-accent};
  --color-brand-accent-light:          #{$color-brand-accent-light};

  --color-brand-border-accent-light:          #{$color-gray-light-c};
  --color-brand-text-accent-light:     #{$color-gray-theme-dark};
  --color-brand-text-accent-light-50:  #{$color-gray-theme-dark-50};
}
