/* sheets styles for panel component

description:
this component is to replicate mat-tab of material

*/
@import "panel-settings";
@import "panel";
@import "panel-header";
@import "panel-container";

