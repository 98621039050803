/*
***
** dropdown menu styles; this displayed when click in a button
***
*/

// variables
$component-color: rgba(0,0,0,0.5);

.dropdown-menu {
  align-items: center;
  display: flex;
  position: relative;

  &__content {
    background-color: $dropdown-menu-bg;
    box-shadow: 0 0 4px 0 $component-color;
    display: none!important;
    min-width: 183px;
    max-width: 183px;
    padding-bottom: 15px;
    position: absolute;
    top: 2.8em;
    z-index: 12;

    // to radiobutton component
    .mat-radio-group {
      display: flex;
      flex-direction: column;
    }
    // to checkbox inside list
    .mat-list,
    .mat-selection-list {
      padding-bottom: 8px;
      padding-top: 0;
    }
    .mat-list-item,
    .mat-list-option {
      color: $color-white;
      height: 40px!important;
      font-size: 12px;
    }
    .mat-list-text {
      color: $color-gray-theme-dark;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block!important;
    }
    .mat-list-option.mat-list-item-focus {
      background: transparent!important; // to replace background inline style
    }
    .mat-list-item-content {
      padding: 0 19px!important; // to replace inline styles
    }
    .mat-list-item .mat-pseudo-checkbox-checked {
      background: var(--color-brand-primary);
    }
    .mat-list-item .mat-pseudo-checkbox-checked.mat-pseudo-checkbox::after {
      color: $color-white;
      height: 6px;
      left: 1px;
      width: 13px;
    }
    &.opened {
      display: block!important;
    }
  }
  &__list {
    max-height: 168px;
    min-height: 88px;
    overflow-y: auto;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
  .rounded-btn {
    font-size: 13px;
  }
}
