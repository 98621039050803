/*
***
** input file filled
***
*/
// variables
$component-bg-color: $color-brand-accent-400;
$component-border-color: $color-brand-accent-900;

.input-file-filled {
  height: 0.1px;
	opacity: 0;
  overflow: hidden;
	position: absolute;
	width: 0.1px;
	z-index: -1;
}

.input-file-filled + label {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 36px;
  margin: 0;
  min-width: 88px;
  outline: 0;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: baseline;
  white-space: nowrap;
}

.input-file-filled + label.color-accent {
  background-color: $component-bg-color;
  border: 1px solid $component-border-color;
  color: $color-white;
}

