/**
 * Dialog custom styles
 */

// variable
$component-bg-color: $dialog-background;
$component-text-first-color: $dialog-text-color;

.mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-container .mat-dialog-content.custom-dialog {
  padding: 0;

  // header with title and asset to close
  .dialog-header {
    align-items: center;
    background-color: $component-bg-color !important;
    color: $component-text-first-color;
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 22px;
    padding-right: 6px;
  }

  .dialog-header .mat-dialog-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 0;
  }

  .dialog-header .close {
    margin-left: auto;
  }

  .dialog-header .close .mat-icon {
    height: 36px;
    width: 36px;
  }

  .dialog-header .close .mat-icon g {
    fill: $component-text-first-color;
    opacity: 1;
  }

  label.flex-33 {
    min-width: 133px;
    max-width: 133px;
  }

}

  //form field with assets inside
.mat-dialog-container .mat-dialog-content.custom-dialog .mat-form-field {
  text-align: left;
  width: 100%;

  .mat-form-field-flex {
    display: flex;
    align-items: center;
  }
}


.mat-dialog-container .mat-dialog-content.custom-dialog .mat-form-field .mat-form-field-suffix {
  .mat-icon.icon-invalid {
    height: 36px;
    width: 36px;
  }

  .mat-icon.icon-valid {
    padding-right: 10px;
    width: 26px;
  }

  .mat-icon.icon-invalid g { opacity: 1; }
  .mat-icon.icon-invalid polygon { fill: $color-brand-warn; }
  .mat-icon.icon-valid polygon { fill: var(--color-brand-primary); }
}

.mat-dialog-container .mat-dialog-actions.custom-dialog {
  padding-bottom: 20px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
}

.mat-dialog-container .mat-dialog-actions.custom-dialog .custom-dialog-button {
  border-color: transparent;
  border-radius: 1px;
  font-size: 12px;
  font-weight: 500;
}
