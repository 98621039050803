/*
 * GENERAL CATALOG STYLES
 */

 .issues-td:hover{
  cursor: pointer;
 }

.catalog-row {
  display: flex;
  flex-wrap: wrap;
}

/******************************************* catalog styles */

.catalog {
   padding: 0!important;
   overflow-y: visible!important;

  /******************************************* header inside catalog */
  &-inner-header {
    align-items: center;
    display: flex;
    min-height: 55px;
    padding-left: 22px;
    padding-right: 19px;

    @if var(--enable-light-skin) {
      background-color: $color-white;
      border: 1px solid $catalog-inner-header-border;
    }
    @else {
      border-bottom-color: $color-brand-accent-900;
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  }

  &-inner-header form {
    flex-wrap: wrap;
  }

  &-inner-header &__input {
    margin-bottom: 0;
  }

  &-inner-header .catalog__button {
    margin-top: 3.5px !important;
  }

  &__title {
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
  }

  &__label {
    font-size: 12px;
    font-weight: normal;
    padding-right: 13px;
    white-space: nowrap;
  }

  /******************************************* label only for details components */
  &__label-detail {
    font-size: 14px;
    padding-right: 40px;
  }

  /******************************************* search component  */
  &__search {
    padding-top: 11px;
    padding-right: 13px;
  }

  &__search .mat-form-field-flex {
    align-items: center;
    border: 1px solid $mat-input-border-color;
    display: flex;
    min-height: 36px;
    padding: 2px 10px;
  }

  &__search:not(.mat-form-field-type-mat-chip-list) .mat-form-field-label,
  &__search .mat-icon,
  &__search .mat-form-field.mat-focused .mat-form-field-label,
  &__search .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $catalog-input-search-color!important;
    @if var(--enable-light-skin) {
      opacity: .6;
    }
  }

  &__search .mat-form-field-underline {
    display: none;
  }

  // remove border to aline correctly with mat-icon inside mat-form-field
  &__search .mat-form-field-infix {
    border: none;
  }

  &__search .mat-form-field-wrapper {
    padding-bottom: .5em;
  }

  &__search .mat-icon {
    cursor: pointer;
    margin-top: 4px;
  }

  &__search.mat-form-field-disabled .catalog__clear-search mat-icon {
      color: $color-white-60;
  }

  /******************************************* icon delete for clear input after search  */
  &__clear-search .mat-icon {
    color: $color-white;
    font-weight: 900!important;
    height: 30px;
    margin-top: 0;
    line-height: 17px!important;
  }

  &__clear-search .mat-icon .mat-button-focus-overlay {
    opacity: 0!important;
  }

  /******************************************* button rounded to add in all list components  */
  &__button {
    border: 1px solid $color-gray-cod!important;
  }

  /******************************************* buttons to action such add polygon on map  */
  &__actions-buttons {
    margin-left: 87px;
    padding-right: 10px;
  }

  &__actions-buttons .mat-icon-button {
    border: 1px solid $catalog-inner-icons-border;
    border-radius: 0;
    margin-right: 10px;
  }

  &__actions-buttons .mat-icon-button .mat-button-ripple-round {
    border-radius: 0;
  }

  &__actions-buttons .mat-icon-button .mat-icon-svg-stroke {
    stroke: $catalog-inner-icons;
  }

  &__actions-buttons .mat-icon-button .mat-icon-svg-fill {
    fill: $catalog-inner-icons;
  }

  /******************************************* buttons group such as 'cancel' / 'edit' / 'save' */
  &__group-buttons {
    margin-left: auto;
  }

  &__group-buttons .mat-button,
  &__group-buttons .mat-raised-button,
  &__group-buttons .mat-flat-button,
  &__group-buttons .mat-stroked-button {
    min-height: 36px!important;
    min-width: 108px;
  }

  /******************************************* group of buttons to add from details */
  &__group-buttons-line {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 10px;
    min-height: 200px;
  }

  &__delete {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 1em;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-out;
  }

  &__delete .mat-fab {
    background-color: $color-light-red;
  }

  &__delete .mat-icon {
    font-size: 26px !important;
    height: 26px !important;
    width: 26px !important;
  }

  &__delete.show {
    visibility: visible;
    opacity: 1;
  }
}



