/*
 * CATALOG TABLE
 * styles for table elements
 */

 /* variables */

 // variables to mat footer
$font-size-footer: 12px;
$text-color-footer:   var(--color-brand-primary);
$border-color-footer: $color-brand-accent-900;


 ///////////////////////////////////////////////////////
.catalog-table {
  table-layout: fixed;
  width: 100%;

  /******************************************* header styles */

  .mat-header-row {
    border-color: $table-cell-header-bottom-border;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    min-height: $table-cell-height;
  }

  .mat-header-row th.mat-header-cell:last-child {
    padding: 20px;
  }

  .mat-header-row .mat-header-cell {
    min-height: 48px;
    @if var(--enable-light-skin) == false {
      background-color:  $table-bg-headers-color;
    }
  }

  .mat-header-row .mat-header-cell .mat-header-cell-label {
    text-align: left;
  }

  .mat-header-row .mat-header-cell .mat-sort-header-arrow {
    display: none;
  }

  .mat-header-row .mat-header-cell .mat-icon {
    margin-left: $table-arrow-margin-left;
  }

  .mat-header-cell,
  .mat-cell {
    color: $table-font-color;
    border-right-color: $table-cell-border;
    border-right-style: solid;
    border-right-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mat-header-cell:last-child,
  .mat-cell:last-child {
    border-right-width: 0;
  }

  /******************************************* row styles */
  .mat-row {
    align-items: stretch;
    background-color: $table-cell-first-color;
    border-color: $table-cell-header-bottom-border;
    border-width: 0px 1px;
    min-height: 40px;
  }

  .mat-row .mat-cell {
    align-items: flex-start;
    color: $table-font-color;
    font-size: 12px;
    padding: 8px 10px 8px;
    text-transform: capitalize;
  }

  .mat-row:last-child {
    border-bottom-width: 1px;
  }
  .mat-row:nth-child(odd) {
    background-color: $table-cell-second-color;
  }

  /******************************************* footer styles */
  .mat-footer-row {
    background-color: $table-cell-second-color;
  }

  .mat-footer-row .mat-footer-cell {
    border: none;
    color: $text-color-footer;
    font-size: $font-size-footer;
    font-weight: 900;
  }

  .mat-footer-row .mat-footer-cell:first-child {
    padding-left: 14px!important;
    text-transform: uppercase;
  }

  .mat-footer-row .mat-footer-cell:last-child {
    padding-left: 24px!important;
  }

  tr.mat-footer-row,
  tr.mat-row {
    height: 32px;
  }

  /******************************************* col width helpers for cols fixed size styles */
  [class*="colw-"] {
    flex-grow: 0; // override the rule flex-grow: 1;
  }

  .colw-10    { flex-basis: 10%; }
  .colw-15    { flex-basis: 15%; }
  .colw-20    { flex-basis: 20%; }
  .colw-33    { flex-basis: 33.33%; }
  .colw-25    { flex-basis: 25%; }
  .colw-40    { flex-basis: 40%; }
  .colw-50    { flex-basis: 50%; }
  .colw-60    { flex-basis: 60%; }
  .colw-70    { flex-basis: 70%; }
  .colw-160px { flex-basis: 160px; }
  .colw-120px { flex-basis: 120px; }


  /******************************************* table link styles */
  &__link {
    color: $table-link-color!important;
    cursor: pointer;
    text-decoration: underline;
  }
}

/******************************************* small table styles */
.catalog-table.catalog-table--small {

  tr.mat-header-row {
    height: 33px;
  }

  tr.mat-header-row .mat-header-cell {
    color: $table-small-cell-header-color;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  tr.mat-row {
    height: 22px;
  }

  td.mat-cell {
    border-bottom-color: transparent;
    padding: 0;
  }

  td.mat-cell:first-child {
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 25px;
    padding-left: 14px !important;
    padding-bottom: 2px;
    padding-top: 2px;
  }
}

/******************************************* medium table styles */
.catalog-table.catalog-table--medium {
  .mat-header-row {
    height: 40px;
    min-height: 24px;
  }

  .mat-header-row .mat-header-cell {
    min-height: 24px;
  }

  .mat-header-row .mat-header-cell:last-child {
    padding-left: 5px!important;

  }

  .mat-header-row .mat-header-cell,
  .mat-row .mat-cell {
    padding: 10px 5px;
  }

  .mat-header-row .mat-header-cell:first-child,
  .mat-row .mat-cell:first-child {
    padding-left: 5px!important;
  }
  .mat-header-row .mat-header-cell:last-child ,
  .mat-row .mat-cell:last-child {
    padding-right: 5px!important;
  }
}

/******************************************* small/medium table styles */
.catalog-table.catalog-table--small,
.catalog-table.catalog-table--medium {
  box-shadow: none; // this is necessary to catalog tables

  // to add an person icon on firts column td
  td.mat-cell.catalog-table__icon-cell .mat-icon {
      font-size: 17px;
      height: 15px;
      width: 14px;
  }
  /*
  tr.mat-header-row .mat-header-cell {
    border-bottom-color: $color-brand-accent-900;
  }
  */
  tr.mat-row {
    background-color: $table-cell-second-color;
  }
  tr.mat-row:nth-child(odd) {
    background-color: $table-cell-first-color;
  }

}

/******************************************* table without border */
.catalog-table--border-none {
  tr.mat-header-row .mat-header-cell,
  .mat-cell {
    border: none;
  }
}

/******************************************* catalog no results message */
.catalog-no-results-message {
  color: $body-base-color;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  padding: 170px 10px 200px 10px;
  text-align: center;
  @if var(--enable-light-skin) {
    border: 1px solid $catalog-border;
    border-top-width: 0;
    opacity: .6;
  }
}

/******************************************* catalog no results message for charts*/
.catalog-no-results-message-chart {
  color: $body-base-color;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  padding: 70px 10px 100px 10px;
  text-align: center;
  max-height: 125px;
  @if var(--enable-light-skin) {
    border: 1px solid $catalog-border;
    border-top-width: 0;
    opacity: .6;
  }
}



/******************************************* table display-cell */
.table-layout--auto {
  table-layout: auto;
}
