/*
 * PIE CHART STYLES
 */

/******************************************* chart-pie styles */
.chart-pie {
  height: 121px;
  position: relative;
  width: 124px;

  &__content {
    max-height: 100%;
    max-width: 100%;
  }

  &__content path {
    cursor: pointer;
  }
  &__content path:hover {
    opacity: .8;
  }
  &__content circle.empty{
    fill: var(--color-brand-border-accent-light);
  }

  &__title {
    align-items: center;
    background-color: $pie-chart-cover-bg-color;
    border-radius: 50%;
    color: $pie-chart-cover-text-color;
    display: flex;
    font-weight: bold;
    justify-content: center;
    height: 90px;
    left: 50%;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
    top: 50%;
    width: 90px;
  }

}

/******************************************* chart-pie-list styles */
.chart-pie-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    align-items: center;
    display: flex;
    font-size: 10px;
    margin: 0 0 8px;
    padding: 0;
  }

  &__shape {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    vertical-align: middle;
    width: 8px;
  }
}
