/**
** Settings: global variables, such as brand colours, configs etc
**/

@import "theme";
@import "color-palettes";
@import "color";
@import "chart-colors";
@import "body-base-settings";
@import "bezier-curves";
@import "breakpoints";

