/*
  border HELPER
*/

$border-defaul-style:      solid;
$border-default-width:     1px;
$border-secondary-width:   2px;

$border-primary-color:     $color-brand-primary;
$border-accent-color:      if( $enable-light-theme, $color-gray-light-c , $color-brand-accent-900);

$border-data: (
  brands: ( primary: $border-primary-color, accent: $border-accent-color ),
  variants: ("all","left", "right", "top", "bottom"),
);

/*
************************************************************  no border */
.b-none{
  border: none;
}
.bb-none{
  border-bottom-width: 0!important;
}
.bl-none{
  border-left-width: 0!important;
}
.br-none{
  border-right-width: 0!important;
}
.bt-none{
  border-top-width: 0!important;
}
.border-1px-dashed-primary {
  border: 1px dashed $border-primary-color;
}
.border-2px-dashed-accent {
  border: 2px dashed $border-accent-color!important;
}

@include borderDirection(1px,$border-data);
@include borderDirection(2px,$border-data);
