/* variables */

$static-page-bg-color:   $color-gray-light-bg;
$static-page-text-color: $color-brand-accent-900;

/* component code
 ******************************************************************************/
 .static-page {
  background-color: $static-page-bg-color;
  height: 100vh;
  padding: 20px;

  &__wrapper {
    flex-direction: column;
    margin-top: 13vh;
    text-align: center;
  }

  &__image {
    display: block;
    margin-bottom: 80px;
    max-width: 100%;
  }

  &__heading-text {
    font-size: 42px;
    font-weight: 300;
    line-height: 42px;
    margin-bottom: 70px;
    margin-top: 0;
  }

  &__heading-text.danger {
    color: $color-brand-danger;
  }

  &__heading-text.warning {
    color: $color-brand-warning;
  }

  &__text {
    color: $static-page-text-color;
    line-height: 24px;
    font-size: 24px;
  }

  &__text a {
    color: var(--color-brand-primary);
  }
}

@media only screen and (max-height: 800px) {
  .static-page {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .static-page__wrapper {
    margin-top: 0;
  }

  // &__heading-text {
  //   margin-bottom: 30px;
  // }
}
