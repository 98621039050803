@import "theme";
@import "color-palettes";
@import "color";


/*chart colors */
$chart-color-coral:   $coral;
$chart-color-blue:    $malibu;
$chart-color-purple:  $dodger-blue;
$chart-color-white:   if( var(--enable-light-skin) , $color-white-60,$color-white );
