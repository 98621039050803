/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import './assets/styles/main';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.h-100 {
  height: 100% !important;
}
$grey-form-field: #727272;
mat-card,
mat-card-content,
mat-tab-group {
  height: inherit !important;
}
.mat-tab-body-wrapper {
  height: 100% !important;
}

.c-green {
  color: green;
}
.bg-green {
  color: rgba(0, 162, 0, 0.8);
}
.bg-red {
  color: rgba(220, 24, 27, 0.8);
}
.c-red {
  color: red;
}
.c-yellow {
  color: rgb(237, 237, 0);
}
.c-gray {
  color: #b4b4b4;
}
.cloud-download-color {
  color: #4b58e9;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: $grey-form-field !important;
}
.custom-dialog-container .mat-dialog-container {
  text-align: left !important;
  padding: 24px 24px 5px 24px !important;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $grey-form-field !important;
}

.mat-form-field-underline {
  /*change color of underline*/
  background-color: $grey-form-field !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $grey-form-field !important;
}
mat-form-field.mat-form-field {
  font-size: 18px !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2a2b29;
  font-weight: bold !important;
}

.overlay-overflow {
  // overflow-y: scroll;
  background-color: #fff;
}

app-ranking-overlay {
  width: 100%;
  padding: 0 !important;
}

.mat-radio-group.ng-invalid.ng-touched .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-group.ng-invalid.ng-touched .mat-radio-label-content {
  color: #f44336;
}

.mat-checkbox.ng-invalid.ng-touched .mat-checkbox-layout .mat-checkbox-label {
  color: #f44336;
}

.mat-checkbox.invalid-check .mat-checkbox-layout .mat-checkbox-label {
  color: #f44336;
}
