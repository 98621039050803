/**
** Components: rich design UI styling. Class names here are more specific.
** This layer usually has the biggest volume
**/

@import "components-settings";
@import "material-components/all";
@import "custom-controls/all";
@import "buttons-theme/all";
@import "flexbox-grid";
@import "breadcrumbs";
@import "main-inner-wrapper";
@import "catalogs/all";
@import "custom-dialogs/all";
@import "input-filled";
@import "line-separator";
@import "figure-icon";
@import "input-file-filled";
@import "image-container";
@import "static-page";
@import "rounded-switch";
@import "circle-icon-btn";
@import "charts/all";
@import "main-container/all";
@import "panel/all";
@import "dropdown-menu";
@import "simple-tab";
