/*
  charts styles - BAR CHART
*/
.chart {
  align-items: flex-end;
  display: flex;
  margin: 2px 0;
  padding: 0;
  width: 100%;
  position: relative;

  .bar__item {
    transition: all .6s ease;
  }
}

.bar {
  font-size: 10px;
  margin-right: 10px;

  &__value { //value or percentage
    display: flex;
    justify-content: center;
    padding-bottom: 2px;
  }

  &__name {
    white-space: nowrap;
  }
}

.chart--yellow {
  min-height: 201px;
  padding: 0 10px;

  .bar {
    width: 30px;
  }

  .bar__item {
    background-color: $color-yellow-b;
    opacity: .8;
  }

  .bar__name {
    margin-top: 4.5em;
    min-width: 100px;
    transform: rotate(36deg);
  }
}


.chart--white {
  min-height: 80px;
  padding-bottom: 15px;

  .bar {
    width: 90px;
  }

  .bar-zero {
    width: 100%;
  }

  .bar-zero .bar__name {
    min-width: 100%;
  }

  .bar__name {
    margin-top: 3px;
    text-align: center;
  }

  .bar__item {
    background-color: $bar-chart-color;
  }
}

// to chart with color inline
.chart--colorful {
  .bar {
    width: 60px;
    margin-right: 2px;
    margin-left: 10px;
    z-index: 1;
  }

  .bar__item {
    background-color: gray;
  }

  .bar__item::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: gainsboro;
  }

  .bar__name {
    margin-top: 3px;
    text-align: center;
  }
}

.chart-axis {
  width: 95%;
  height: 100%;
  position: absolute;

  &__y {
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: gray;
  }

  &__y span {
    position: absolute;
    left: -26px;
    bottom: -5px;
    font-size: 10px;
    text-align: right;
    width: 22px;
  }
}

@media only screen and (max-width: 1539px) {
  .bar {
    margin-right: 7px;
    width: 24px;
  }
}

//responsive
@media only screen and (max-width:  $lg-breakpoint) {
  .bar {
    margin-right: 10px;
    width: 30px;
  }
}
