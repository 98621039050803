/*
 * TEXT HELPER
 */


 /*
 ************************************************************  font-weights*/
 .fw-light    { font-weight: 300 }
 .fw-regular  { font-weight: 400 }
 .fw-medium   { font-weight: 500 }
 .fw-semibold { font-weight: 600 }
 .fw-bold     { font-weight: 700!important }

 /*
 ************************************************************  white space styles*/
  .nowrap{  white-space: nowrap; }
 /*
 ************************************************************  text colors */
 .text-primary        { color: $color-brand-primary;}
 .text-gray-dark      { color: $color-gray-dark;}
 .text-gray-light     { color: $color-gray-theme-dark-50; }
 .text-danger         { color: $color-brand-warn; }
 .text-danger-light   { color: $color-light-red; }
 .text-white          { color: $color-white; }
 .text-white-60       { color: $color-white-60; }

 .text-yellow   { color: $color-yellow; }
 .text-success  { color: $color-brand-success; }
 .text-warning  { color: $color-brand-warning; }

  /*
 ************************************************************  text-alignment*/
 .text-center   { text-align: center!important;}
 .text-left     { text-align: left!important; }
 .text-right    { text-align: right; }

 /*
 ************************************************************  text-transformation*/
 .text-uppercase    { text-transform: uppercase; }
 .text-capitalize   { text-transform: capitalize; }
 .text-lowercase    { text-transform: lowercase; }
 .text-underline    { text-decoration: underline; }
 /*
 ************************************************************  text-font-size*/
 .fs-10px { font-size: 10px }
 .fs-11px { font-size: 11px }
 .fs-12px { font-size: 12px !important }
 .fs-13px { font-size: 13px !important}
 .fs-14px { font-size: 14px }
 .fs-15px { font-size: 15px }
 .fs-16px { font-size: 16px !important}
 .fs-18px { font-size: 18px !important}
 .fs-20px { font-size: 20px !important }
 .fs-21px { font-size: 21px }
 .fs-24px { font-size: 24px }
 .fs-30px { font-size: 30px }
 .fs-32px { font-size: 32px !important;}

  /*
 ************************************************************  truncate text*/
 .truncate-text {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }

.wrap-text {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

/*******       Dashboard text***********/
.dashboard__title{
  font-size: 20px;
  display: inline-block;
  color: $color-gray-theme-dark;
  p{
      margin-top: 0;
      margin-bottom: 0;
  }
}
