.simple-tab {
  .mat-tab-header {
    border-bottom-style: none;
  }

  .mat-tab-label {
    color: $simple-tab-color;
    font-size: 11px;
    font-weight: bold;
    height: 31px;
    min-width: 98px;
    opacity: 1;
    padding: 0 16px;
  }

  .mat-tab-label:focus:not(.mat-tab-disabled),
  .mat-tab-label.mat-tab-label-active {
    color: var(--color-brand-primary);
  }
}
