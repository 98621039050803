/*
 *  CATALOG INPUTS STYLES
 */

 .catalog {
  /******************************************* inputs styles */
  &__input {
    margin-bottom: 32px;
    justify-content: space-between;
  }

  &__input > label {
    color: $catalog-input-label;
    font-size: 12px;
    line-height: 14px;
    padding-right: 16px;
  }

  &__input > label.label-error {
    color: $color-brand-warn;
  }

  &__input-field .error {
    margin-top:4px;
    color: $color-brand-warn;
  }

  /* bullet before inputs */
  &__input.catalog__input-bullet::before {
    position: absolute;
    content: '\00B7';
    font-weight: bold;
    left: -20px;
  }

  /******************************************* data detail & add-input */

  &__data-detail {
    margin-bottom: 36px;
  }

  &__data-detail span{
    font-weight: 900;
  }

  &__data-detail label,
  &__data-detail span {
    font-size: 12px;
    width: 100%;
  }


  &__input,
  &__data-detail,
  &__add-input .mat-button-wrapper,
  &__add-input .mat-icon {
    align-items: center;
    display: flex;
  }

  &__add-input {
    background-color: transparent!important;
    border: 1px solid $color-brand-accent-900;
    color: $color-white;
    margin-top: 3px!important;
    min-width: 36px!important;
    max-width: 36px!important;
    max-height: 36px;
    min-height: 36px;
    padding: 0;

  }

  &__add-input .mat-button-wrapper,
  &__add-input .mat-icon {
    justify-content: center;
  }
 }
