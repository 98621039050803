/*
  styles to options panel component
*/
/* settings */
$panel-width: 300px;


/* //////////// */
.catalog-options-panel {
  display: flex;
  margin-top: 10px;
  min-height: 385px;
  width: 80%;

/******************************************* list */
  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__list .mat-list {
    padding-top: 0px;

    .mat-list-item {
      color: $color-white;
      font-size: 12px;
      height: 36px;
    }

    .mat-list-item .mat-list-item-content {
      display: inherit;
      padding: 0 16px 0 20px !important;
    }

    .mat-list-item .mat-list-item-ripple {
      display: none;
    }

    .mat-list-item .mat-list-text {
      padding: 0;
      margin: 0;
      display: inline;
      min-height: 100%;
    }

    .mat-list-item .mat-checkbox {
      color: $catalog-option-list-text-color;
      margin-bottom: 12px;
    }

    .mat-list-item .mat-checkbox:last-child {
      margin-bottom: 0;
    }

    //mat checkbox content
    .mat-list-item .mat-checkbox-layout {
      max-width: 100%;
    }

    .mat-list-item .mat-checkbox-layout .mat-checkbox-label {
      font-size: 12px;
      margin-left: 30px;
      @extend .truncate-text;
    }

  }


/******************************************* header */
  &__header {
    background-color: $catalog-option-panel-header-bg;
    display: flex;
    padding-left: 20px;
    padding-right: 16px;
  }

  &__header .mat-icon {
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__header h3 {
    display: flex;
    height: 100%;
    justify-content: center;
    font-size: 12px;
    padding-left: 15px;
  }

/******************************************* content */
  &__content {
    max-height: 300px;
    min-height: 300px;
    min-width: $panel-width;
    max-width: $panel-width;
    overflow: auto;
    background-color: $catalog-option-panel-bg;
  }

/******************************************* group-buttons */
  &__group-buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 38px;
  }

  &__group-buttons .mat-flat-button,.mat-stroked-button {
    margin-bottom: 14px;
    min-width: 180px;
  }

  @if var(--enable-light-skin) {
    &__group-buttons .mat-flat-button,
    &__group-buttons .mat-stroked-button .mat-icon-svg{
      fill: $color-gray-dark;
    }
  }

/******************************************* image list in dialog */
  &__image-list {
    display: flex;
    flex-direction: column;
    max-height: 240px;
  }

  &__image-list.list-saved {
    margin-left: 48px;
  }

  &__image-list,
  &__image-list.list-saved {
    max-width: 344px;
    min-width: 344px;
  }

  &__image-list div.flex-wrap {
    margin-left: -2.5px;
    overflow-y: auto;
  }

  &__image-list .figure-icon {
    min-height: 62px;
    min-width: 102px;
  }

  &__image-list .figure-icon:hover,
  &__image-list .figure-icon.active {
    border: 3px solid $catalog-options-panel-image-list-active;
  }

}

/******************************************* responsive */
@media only screen and (max-width: $lg-breakpoint) {
  .catalog-options-panel {
    width: 100%;
  }
}

