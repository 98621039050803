/*
 * ROOT ELEMENTS (body, html)
 */


html {
  box-sizing: border-box;
  height: 100%;
}

body {
  box-sizing: border-box;
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  font-family: $body-roboto-font;
  font-size: $body-size-font;
  background-color: $body-background-color;
  margin: 0;
}

/* remove yellow color of fields with value */
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px $color-white;
}

*:focus {
  outline: none;
}

table {
  border-spacing: 0;
}

a {
  cursor: pointer;
}
/*
************************************************************  scrollbar classes */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: $body-scroll-bg-thumb-color;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: $body-scroll-bg-track-color;
  border-radius: 10px;
}

/*
************************************************************  dragable classes */
.dnd-drag-start,
.dnd-sortable-drag {
  background-color:$body-default-dragable-bg-color;
}

.dnd-drag-enter,
.dnd-drag-start,
.dnd-sortable-drag,
.dnd-drag-over {
  opacity: $body-default-dragable-opacity;
}
