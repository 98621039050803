/* custom styles for ngx-charts */
.custom-chart {
  margin-right: 1.2em;
  max-width: 100%;
  overflow-x: auto;

  .ngx-charts text {
    fill: if( var(--enable-light-skin), $tuna, $color-white) !important;
  }

  /// chart legend */
  .chart-legend .legend-labels {
    background: none;
    transform: rotate(180deg);
  }
  .chart-legend .legend-label {
    cursor: default;
    transform: rotate(-180deg);
  }

  .chart-legend > div:first-child{
    width: 180px !important;
  }

  .chart-legend .legend-label,
  .chart-legend .legend-label:hover,
  .chart-legend .legend-label .active .legend-label-text {
    color: $legend-label-color!important; // to replace original settings on chart library
  }

  .chart-legend .legend-label-color {
    border-radius: 50%!important;
    height: 8px!important;
    margin-top: 4px;
    width: 8px!important;
  }

  .chart-legend .legend-label-text {
    font-size: 10px;
  }
  /// */

  &.custom-chart__legend-bottom .ngx-charts-outer {
    align-items: flex-end;
    display: flex;
    width: auto !important;
  }
  &.custom-chart__legend-bottom .ngx-charts-outer .chart-legend {
    padding-bottom: 20%;
  }

  &.custom-chart-dark-axis .axis.x,
  &.custom-chart-dark-axis .axis.y {
    opacity: .5;
  }

  //custom legend

  &__legend {
    font-size: 10px;
    padding-left: 20px;
  }

  &__legend .legend-title,
  &__legend .legend-value {
    margin-right: 6px;
  }

  &__legend .legend-value {
    font-weight: bold;
  }
}
