ngx-charts-tree-map{
  color: white!important;
  .tree-map{
    color: white!important;
  }
  .cell{
    color: white!important;
  }
  p{
    color: white!important;
    font-size: 12px!important;
  }
}
.treemap-val{
  &::after{
    content: "% ";
  }
  word-wrap:break-word!important;
  visibility: visible!important;
}

.treemap-label{
  word-wrap:break-word!important;
  visibility: visible!important;
}

.tooltip-crit{
  p{
    font-size: 12px;
  }
  .model-name{
    font-size: 12px!important;
  }
  .model-value{
    font-size: 18px!important;
  }
} 
