/*
 * SIDENAV
 */


/* main content container
******************************************************************************/
.mat-drawer-container.mat-sidenav-container {
  color: $body-base-color;
  display: flex;
  min-height: calc(100vh - #{$toolbar-height});
}

/* sidenav container
******************************************************************************/
.mat-drawer.mat-sidenav {
  background-color: $sidenav-background-color;
  position: static;
  width: $sidenav-default-width;
}

.mat-sidenav.mat-drawer,
.mat-sidenav .mat-list {
  overflow: visible;
}

.mat-sidenav.mat-drawer,
.mat-sidenav .mat-list-item {
  transition: transform $sidenav-transition-time $easing-out;
  will-change: transform;
}

/* mat list
******************************************************************************/
.mat-sidenav .mat-list {
  min-height: 100%;
  padding-top: 0px;

  .mat-list-item-content {
    align-items: flex-start!important;
  }

  .mat-list-text h4.mat-line {
    display: flex;
    align-items: center;
  }
}

/* sidenav mat-list-item
******************************************************************************/
.mat-sidenav mat-list-item.mat-list-item {
  height: $sidenav-item-height;
}

/* sidenav mat-list-item
******************************************************************************/
.mat-sidenav .mat-list .mat-list-item {
  color: $sidenav-items-default-color;
  cursor: pointer;
  font-size: $sidenav-item-font-size;
  transition: background-color .15s $standard-curve;
  will-change: background-color;

  // &:first-child {
  //   margin-top: 53px;
  // }

  /* mat-icon */
  .mat-icon {
    box-sizing: border-box;
    padding: 0 1px;
  }
  .mat-icon .mat-icon-svg,.mat-icon-svg-shadow {
    fill: $sidenav-svg-color;
    fill-opacity: 0.8;
  }
  .mat-icon .mat-icon-svg-transparence {
    fill: $sidenav-svg-transparence;
  }

  /* mat-line */
  .mat-line {
    color: $sidenav-items-default-color;
    font-weight: bold;
    transition: color .15s $standard-curve;
    will-change: color;
  }

  /* mat.list.item sub-list */
  &.active .sidenav-list__item-sublist a.is-active {
    color: $sidenav-items-hover-color;
    opacity: 1;
  }

  &:not(.sidenav-list__item) .mat-line:hover {
    color: $sidenav-items-hover-color;
  }

  // hover & active state
  &:hover mat-icon .mat-icon-svg,
  &.active mat-icon .mat-icon-svg {
    fill: $sidenav-svg-hover-color;
    fill-opacity: 1;
  }

  @if var(--enable-light-skin) {
    &.active mat-icon .mat-icon-svg-shadow,
    &:hover mat-icon .mat-icon-svg-shadow {
      fill: $sidenav-svg-hover-color;
      stroke: $sidenav-svg-hover-color;
    }
  }
}

/* sidenav list > sub-list
******************************************************************************/
.sidenav-list__item {
  height: 100%!important;

  .mat-icon {
    padding-top: 8px;
  }

  h4.mat-line {
    cursor: default;
    height: 39px;
    display: flex;
    align-items: center;
  }

  &-sublist a {
    cursor: pointer;
    height: 39px;
    display: flex;
    align-items: center;
    margin: 0;

    color: $sidenav-items-default-color;
    text-decoration: none;
    opacity: .6;
  }

  &-sublist a:hover,
  &-sublist a.active {
    color: $sidenav-items-hover-color;
    opacity: 1;
  }
}

/* when sidenav is collapsed
******************************************************************************/
.mat-sidenav.sidenav-is-collapsed {
  // override styles generated by the library
  transform: translateX(-($sidenav-default-width - $sidenav-collapsed-width)) !important;
  padding-top: 0px!important;

  mat-list-item.mat-list-item {
    height: $sidenav-item-height!important;
  }

  /********        sidenav-list__item */
  .sidenav-list__item h4.mat-line {
    margin-top: 8px;
  }

  .sidenav-list__item .mat-line a,
  .sidenav-list__item h4.mat-line {
    display: flex;
    align-items: center;
  }

  .sidenav-list__item .mat-line a:last-child {
    margin-bottom: 8px;
  }

  .sidenav-list__item-sublist {
    display: none;
    margin-top: 0px;
    overflow-y: auto;
    max-height: 234px;
  }


  // adjustment for move the icons to the right
  // when the sidenav is collapsed
  .mat-list-item {
    transform: translateX(($sidenav-default-width - $sidenav-collapsed-width));
    width: $sidenav-collapsed-width;
  }

  // collapsed hover tooltips
  .mat-list .mat-list-item-content {
    padding: 0;
    justify-content: center;
    align-items: center !important;
  }

  .mat-list,
  .mat-nav-list,
  .mat-selection-list {
    padding-top: 0px;
  }

  .mat-list .mat-icon {
    margin: 0 auto;
  }

  .mat-list .subitem-icon {
    margin: 0 !important
  }

  .mat-list .mat-line {
    font-size: 14px;
  }

  .mat-list-item .mat-list-text {
    background-color: $sidenav-sub-list-bg;
    position: absolute;
    left: 100%;
    top: 0;

    min-height: $sidenav-item-height;
    width: auto;
    min-width: $sidenav-collapsed-hover-width;
    justify-content: center;

    padding-top: 0px!important;

    transform: translateX(-10%) scale(.75);
    transform-origin: left center;
    visibility: hidden;
    will-change: transform visibility;
  }

  // active hover state

  .mat-list-item:hover .mat-list-text {
    transform: translateX(0) scale(1);
    transition: all .1s $easing-in;
    visibility: visible;
    background-color: $sidenav-sub-list-bg;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  }

  .mat-list-item:hover .mat-list-text .sidenav-list__item-sublist {
    display: block;
  }

}

/* when sidenav is not collapsed
******************************************************************************/
.mat-sidenav.sidenav-is-not-collapsed .mat-list {
  .mat-list-item:first-child {
    margin-top: 0px;
  }

  .mat-list-item:not(.sidenav-list__item) .mat-icon {
    padding-top: 0;
  }

  .mat-list-item:not(.sidenav-list__item) .mat-list-item-content {
    align-items: center!important;
  }

  .mat-list-item.sidenav-list__item .mat-icon {
    height: 39px;
    display: flex;
    align-items: center;
  }

  .mat-list-item:not(.sidenav-list__item).active .mat-line {
    color: $sidenav-items-hover-color;
  }
}



