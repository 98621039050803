/*
 * GLOBAL COLOR SETTINGS
 * color setting for all components
 */

 @import '../tools/all';

 /* no semantic colors
  ******************************************************************************/
  $tuna:              #323235;
  $mine-shaft-b:      #343434;
  $mine-shaft:        #3D3D3D;
  $cod-gray:          #181818;
  $dusty-gray:        #999999;
  $silver:            #c5c5c5;
  $athens-gray:       #EFF2F5;
  $mercury:           #E3E1E1;
  $mischka:           #E1E2E7;

  $port-gore:         #28255F;
  $dodger-blue-10:    rgba(71,128,255,.1);
  $jacksons-purple:   #204495;
  $cerulean-blue:     #2D58B7;
  $dodger-blue:       #4780FF;
  $dodger-blue-light: #edf3fe;
  $vivaorganica-primary-light: #fddc3a1a;
  $pacific-blue:      #0197C6;
  $malibu:            #49D4FF;
  $bright-sun:        #FEDC3B;
  $sunshade:          #FFA42C;
  $coral:             #FF7C59;
  $outrageous-orange: #FF592D;
  $radical-red:       #FF3C50;

  /* brand colors
  ******************************************************************************/
  $color-brand-primary:            getMapValue($primary-color-palette, 500);
  $color-brand-primary-10:         $dodger-blue-10;
  $color-brand-accent:             getMapValue($accent-default-color-palette, 500);
  $color-brand-accent-light:       getMapValue($accent-secondary-color-palette, 500); // #F4F5F7
  $color-brand-accent-light-800:   getMapValue($accent-secondary-color-palette, 800); // #eff0f3

  $color-brand-warn:               getMapValue($warn-color-palette, 500);

  $color-brand-accent-400:         getMapValue($accent-default-color-palette,400);
  $color-brand-accent-600:         getMapValue($accent-default-color-palette,600);
  $color-brand-accent-900:         getMapValue($accent-default-color-palette,900);

  /* gray scale
  ******************************************************************************/
  $color-white:             #FFF;
  $color-black:             #000;

  $color-gray-theme-dark:    $tuna;
  $color-gray-theme-dark-50: lighten($tuna,50%);
  $color-gray-theme-dark-75: lighten($tuna,75%);
  $color-gray-cod:           $cod-gray;
  $color-gray-dark-b:        $mine-shaft-b;
  $color-gray-dark:          $mine-shaft;
  $color-gray-light:         $silver;
  $color-gray-light-b:       $mercury;
  $color-gray-light-c:       $mischka;
  $color-white-60:           $dusty-gray;

  $color-gray-light-bg:      $athens-gray;


  /* others colors
  ******************************************************************************/
  $color-blue-dark:         $jacksons-purple;
  $color-blue-dark-b:       $cerulean-blue;
  $color-blue-light:        $pacific-blue;
  $color-medium-purple:     $dodger-blue;
  $color-primary-light:     $vivaorganica-primary-light;
  $color-dark-purple:       $port-gore;
  $color-yellow:            $bright-sun;
  $color-yellow-b:          $sunshade;
  $color-light-red:         $radical-red;
  $color-orange:            $outrageous-orange;

  /* state colors
  ******************************************************************************/
  $color-brand-success:     $malibu;
  $color-brand-danger:      $color-brand-warn;
  $color-brand-warning:     $coral;

