/*
 * border mixin
   For create all border styles:
   EJ.
    .border-primary-1px
    .border-primary-top-2px
    .border-accent-left-2px

  Its neccessary receive list of colors, list of brands, list of width and directions
 */



 @mixin borderDirection($size,$border-data) {
  @each $v_value in map-get($border-data, variants){
    @each $brand_key, $brand_value in map-get($border-data, brands) {
      @if $v_value == 'all'{
          // complete brand border width two width
          .border-#{$brand_key}-#{$size} {
            border: $size solid $brand_value;
          }

        }@else{
          // specific border side with two sizes by brand
          .border-#{$brand_key}-#{$v_value}-#{$size} {
            border-#{$v_value}: $size solid $brand_value;
          }
      }
    }
  }
}

