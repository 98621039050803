/*
**
* panel container
**
*/

.panel-container {
  background-color: $panel-tab-body-color;
  color: $panel-color;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
  flex-basis: 100%;
}

.panel-container.panel-container-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
}
