/*
  styles to circle icon btn;
*/
//variables


.circle-icon-btn {
  background-color: transparent;
  border: 1px solid $circle-icon-btn-border-color;
  border-radius: 50%;
  cursor: pointer;
  max-width: 24px;
  max-height: 24px;
  padding: 4px 0;

  &:hover {
    background-color:  $circle-icon-btn-bg-hover-color;
  }

  // icon inside button
  svg {
    height: 12px;
    width: 12px;
  }

  svg .mat-icon-svg {
    fill:  $circle-icon-btn-color;
  }

  &:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
}
