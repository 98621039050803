/*
 * USER MENU IN THE TOOLBAR
 */


 .toolbar-user-menu.mat-menu-panel {
  min-width: $toolbar-user-menu-width;
  border-radius: 0;
}

.toolbar-user-menu {
  // default styles for items
  .mat-menu-item {
    color: $color-dark-purple;
    font-size: 12px;
    height: 49px;
    padding-left: 17px;
    padding-right: 17px;
  }

  // user information
  &__information.mat-menu-item {
    height: auto;
    line-height: 1.25;
    padding-top: 3px;
    padding-bottom: 15px;
  }

  &__mail,
  &__username {
    color: $color-dark-purple;
    line-height: 14px;
    margin: 0;
    opacity: .9;
  }

  &__username {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  &__mail {
    font-weight: 300;
  }

  // item hover state
  .mat-menu-item:not(.toolbar-user-menu__information):hover {
    background-color: $color-brand-primary;
    color: $color-white;
  }
}

