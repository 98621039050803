.catalog-content {
  color: $catalog-tab-text-color;
  min-height: calc(100vh - 220px);
  padding: 18px 12px 18px 20px;

  @if var(--enable-light-skin) {
    background-color: $color-white;
    border: 1px solid $catalog-border;
    border-top-width: 0;
    margin-bottom: 13px;
  }

  &.catalog-content-detail {
    max-height: calc(100vh - 215px);
    margin-bottom: 0;
    overflow-x: auto;
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: calc(100vh - 210px);
    max-width: 425px;
    padding-bottom: 17px;
  }
}



