/* styles for nav header */


.panel {
  display: flex;
  flex-direction: column;

  .ink-Bar {
    background: var(--color-brand-primary);
    position: absolute;
    bottom: 0;
    height: $panel-tab-ink-bar-height;
    transition: $mat-tab-animation-duration $ease-in-out-curve-function;
  }
}
