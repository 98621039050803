/*
 * SETTINGS FOR CHARTS
 */

$legend-label-color:          if( var(--enable-light-skin), $color-gray-dark, $color-white);

$bar-chart-color:             if( var(--enable-light-skin), $color-gray-dark, $color-white);

$pie-chart-cover-bg-color:    if( var(--enable-light-skin), $color-white, $color-gray-dark-b);
$pie-chart-cover-text-color:  if( var(--enable-light-skin), $color-gray-dark-b, $color-white);


/* Progress chart */
$progress-bar-empty-bg: $color-gray-light-c;
$progress-bar-dark-bg:  $color-blue-dark-b;
$progress-bar-light-bg: $color-blue-light;
