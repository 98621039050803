/*
 * SPACING HELPER
 */


/*
************************************************************  zero margin spacing*/
.m-0 { margin: 0 !important }

/*
************************************************************  axis -x- margin spacing*/
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-6px {
  margin-left: 6px;
  margin-right: 6px;
}

.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-28px {
  margin-left: 28px;
  margin-right: 28px;
}
/*
************************************************************  axis -y- margin spacing*/
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2px {
  margin-top: 2px!important;
  margin-bottom: 2px!important;
}

.my-8px {
  margin-top: 8px!important;
  margin-bottom: 8px!important;
}

.my-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}
/*
************************************************************  auto margin spacing*/
.mt-auto { margin-top: auto; }
.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
.mb-auto { margin-bottom: auto; }

/*
************************************************************  top margin spacing*/
.mt-0     { margin-top: 0; }
.mt-3px   { margin-top: 3px; }
.mt-6px   { margin-top: 6px; }
.mt-7px   { margin-top: 7px; }
.mt-10px  { margin-top: 10px; }
.mt-12px  { margin-top: 12px; }
.mt-14px  { margin-top: 14px!important; }
.mt-16px  { margin-top: 16px;}
.mt-18px  { margin-top: 18px!important; }
.mt-30px  { margin-top: 30px; }
.mt-50px  { margin-top: 50px; }

/*
************************************************************  left margin spacing*/
.ml-0     { margin-left: 0; }
.ml-3px   { margin-left: 3px; }
.ml-6px   { margin-left: 6px; }
.ml-7px   { margin-left: 7px!important; }
.ml-10px  { margin-left: 10px; }
.ml-15px  { margin-left: 15px; }
.ml-16px  { margin-left: 16px; }
.ml-20px  { margin-left: 20px; }
.ml-28px  { margin-left: 29px }
.ml-21px  { margin-left: 21px; }
.ml-45px  { margin-left: 45px; }
.ml-54px  { margin-left: 54px; }
.ml-150px { margin-left: 150px; }

/*
************************************************************  right margin spacing*/
.mr-0     { margin-right: 0; }
.mr-10    { margin-right: 10%; }
.mr-7px   { margin-right: 7px; }
.mr-8px   { margin-right: 8px!important; }
.mr-10px  { margin-right: 10px;}
.mr-12px  { margin-right: 12px!important;}
.mr-15px  { margin-right: 15px;}
.mr-16px  { margin-right: 16px;}
.mr-20px  { margin-right: 20px!important;}
.mr-24px  { margin-right: 24px; }
.mr-28px  { margin-right: 28px; }
.mr-30px  { margin-right: 30px; }
.mr-32px  { margin-right: 32px; }
.mr-36px  { margin-right: 36px; }
.mr-45px  { margin-right: 45px; }
.mr-54px  { margin-right: 54px; }
.mr-60px  { margin-right: 60px; }

//bottom spacing
.mb-0     { margin-bottom: 0; }
.mb-3px   { margin-bottom: 3px; }
.mb-6px   { margin-bottom: 6px; }
.mb-8px   { margin-bottom: 8px!important; }
.mb-10px  { margin-bottom: 10px!important; }
.mb-12px  { margin-bottom: 12px!important; }
.mb-13px  { margin-bottom: 13px; }
.mb-14px  { margin-bottom: 14px; }
.mb-16px  { margin-bottom: 16px; }
.mb-18px  { margin-bottom: 18px!important; }
.mb-20px  { margin-bottom: 20px!important; }
.mb-25px  { margin-bottom: 25px; }
.mb-30px  { margin-bottom: 30px!important; }


/* ****** padding spacing ***********/

/*
************************************************************  zero padding*/
.p-0 { padding: 0 !important; }

/*
************************************************************  left padding */
.pl-0     { padding-left: 0!important;}
.pl-2px   { padding-left: 2px;}
.pl-10px  { padding-left: 10px;}
.pl-12px  { padding-left: 12px!important;}
.pl-19px  { padding-left: 19px;}
.pl-35px  { padding-left: 35px!important;}

/*
************************************************************  right padding */
.pr-0      { padding-right: 0!important;}
.pr-8px    { padding-right: 8px!important;}
.pr-10px   { padding-right: 10px;}
.pr-15px   { padding-right: 15px!important;}
.pr-37px   { padding-right: 37px!important;}

/*
************************************************************  top padding */
.pt-0     { padding-top: 0 !important }
.pt-2px   { padding-top: 2px !important}
.pt-3px   { padding-top: 3px !important}
.pt-4px   { padding-top: 4px }
.pt-5px   { padding-top: 5px; }
.pt-6px   { padding-top: 6px }
.pt-10px  { padding-top: 10px }
.pt-14px  { padding-top: 14px }
.pt-16px  { padding-top: 16px }
.pt-18px  { padding-top: 18px }
.pt-34px  { padding-top: 34px }

/*
************************************************************  bottom padding */
.pb-3px   { padding-bottom: 3px; }
.pb-4px   { padding-bottom: 4px; }
.pb-5px   { padding-bottom: 5px; }
.pb-8px   { padding-bottom: 8px; }
.pb-10px  { padding-bottom: 10px; }
.pb-30px  { padding-bottom: 30px; }

/*
************************************************************  axis -x- padding */
.px-0 {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.px-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.px-6px {
  padding-left: 6px;
  padding-right: 6px;
}

.px-9px {
  padding-left: 9px;
  padding-right: 9px;
}

.px-12px {
  padding-left: 12px!important;
  padding-right: 12px!important;
}

.px-19px {
  padding-left: 19px;
  padding-right: 19px;
}

.px-22px {
  padding-left: 22px;
  padding-right: 22px;
}

.px-35px {
  padding-left: 35px!important;
  padding-right: 35px!important;
}

/*
************************************************************  axis -y- padding */
.py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.py-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-9px {
  padding-top: 9px;
  padding-bottom: 9px;
}

.py-12px {
  padding-top: 12px!important;
  padding-bottom: 12px!important;
}

.py-15px {
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.py-16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-34px {
  padding-top: 34px;
  padding-bottom: 34px;
}
