.theme-button-remove {

  @if var(--enable-light-skin) {
    &.mat-warn {
      border-color: $button-remove-light-theme-border-color-warn!important;
    }
  }@else {

    &.mat-warn {
      background-color: $button-remove-dark-theme-color-warn;
      border-color: transparent;
      color: $color-white;
    }
  }
}
