/**
 * Image container -FOR CUSTOM COMPONENTS
 */

 .image-container {
  background-color:  $component-image-container-bg!important;
  height: 35px;
  width: 58px;

  &.image-container--empty {
    border: 1px solid $color-brand-warn;
  }

  &.image-container--large {
    width: 210px;
    height: 91px;
  }
}
