.catalog-table.catalog-table-sticky {
  min-width: 100%;
  table-layout: fixed;

  .mat-table-sticky {
    background-color: $table-cell-first-color;
    position: sticky;
    left: 0;
  }

  /////////////////////////////////////////////////// header heigth
  .mat-subheader-row th,
  th:not(.mat-table-sticky),
  .mat-header-row {
    height: 23px!important;
    min-height: 23px;
  }
  th.mat-table-sticky {
    height: 41px;
    min-height: 41px;
  }
  /////////////////////////////////////////////////// header th
  th.mat-table-sticky {
    //border-bottom-color: $color-brand-accent-900;
    text-align: center;
  }

  td.mat-table-sticky:first-child {
    text-align: left;
  }

  /////////////////////////////////////////////////// sub header
  .mat-subheader-row th {
    color: $color-brand-warning;
    font-size: 10px;
    font-weight: normal;
  }

  /////////////////////////////////////////////////// body
  .mat-row td {
    background-color: $table-cell-first-color;
    text-align: center;
  }
  .mat-row:nth-child(odd) td {
    background-color: $table-cell-second-color;
  }
  tr td:first-child {
    width: 100%;
  }
  .mat-table-sticky--half {
    width: 70px;
  }

  ///////////////////////////////////////////////////  footer
  .mat-footer-row td {
    background-color: $table-cell-second-color;
  }
  .mat-footer-row td:last-child {
    padding: 0 10px !important;
  }
}
