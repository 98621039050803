/**
 * line separator styles
 *
*/



// used in details components - horizontal
.line-separator {
  background-color: $component-line-separator-bg;
  height: 1px;
  margin-top: 13.5px;
  margin-bottom: 13.5px;
  min-width: 112px;
  position: relative;
}

.line-separator::before {
  background-color: $component-line-separator-before-bg;
  content: 'o';
  font-size: 17px;
  height: 20px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  top: -11px;
  width: 20px;
}

// used vertical line-blurred-separator
[class*="line-blurred-separator"] {
  position: relative;
}

[class*="line-blurred-separator"]::before {
  background-color: $component-second-bg-color;
  content: '';
  opacity: .6;
  position: absolute;
  top: 50%;
  width: 1px;
}


.line-blurred-separator--center::before {
  height: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.line-blurred-separator--end::before {
  height: 100%;
  right: 0;
  transform: translateY(-50%);
}
