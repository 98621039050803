/*
 * CUSTOM MATERIAL CHECKBOX
 */

 .mat-checkbox{

  &.mat-accent:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $mat-checkbox-second-border-color;
  }

  &.mat-accent.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--color-brand-primary);
  }

  .mat-checkbox-checkmark-path {
    stroke: $mat-checkbox-checked-color!important;
  }

 }

.mat-checkbox.rounded-checkbox {

  .mat-checkbox-inner-container {
    margin-right: 12px;
    height: 30px;
    width: 30px;
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 50%
  }

  .mat-checkbox-layout {
    display: flex;
    align-items: center;
  }

  &:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $mat-checkbox-first-border-color!important;
  }
}
