/*
 * CATALOG RESPONSIVE
*/

@media only screen and (max-width: $md-breakpoint) {
  .catalog {
    .catalog-inner-header {
      flex-wrap: wrap;
      padding-bottom: 10px;
    }

    .catalog__actions-buttons {
      margin-left: auto;
    }

    .catalog__actions-buttons .mat-icon-button:last-child {
      margin-right: 0;
    }

    .catalog__canvas-map {
      border-right: none!important;
    }
  }

  .catalog-content {
    overflow-y: auto;

    &__column {
      flex-wrap: nowrap;
      //max-height: 100%;
    }
  }

}

@media only screen and (max-width: $lg-breakpoint) {
  .catalog-table.catalog-table--small tr.mat-header-row .mat-header-cell,
  .catalog-table .mat-row .mat-cell {
    font-size: 10px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .sidenav-is-not-collapsed .catalog-checkbox-list {
    max-width: 100%;
    .mat-list-text {
      margin: 12px;
    }
  }
}

@media only screen and (max-width: $md-second-breakpoint) {
  .catalog-content__column {
    max-height: calc(100vh - 160px);
  }
  .catalog-table.catalog-table--small tr.mat-header-row .mat-header-cell,
  .catalog-table .mat-row .mat-cell {
    font-size: 9px;
  }
}
