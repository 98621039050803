/* desciptions based on this ITCSS Boilerplate
 * https://github.com/alanshortis/itcss-boilerplate
 */

/*
 * SETTINGS
 * Global SASS variables, maps, etc.
 */  @import "settings/all";


/*
 * NATIVE CSS VARIABLES
 */  @import "settings/global-native-css-variables";


/*
 * TOOLS
 * SASS functions and mixins.
 */  @import "tools/all";



/*
 * GENERIC
 * Very far reaching selectors. CSS resets where elements are
 * selected directly and restting non-standard form behaviours.
 */  @import "generic/all";



/*
 * BASE
 * Default styling on elements without classes, such as
 * typography (h1, h2, etc) and base elements (html, body).
 */  @import "base/all";



/*
 * COMPONENTS
 * Defined parts of the site (navigation, header, footer, carousel)
 * selected with classes, using BEM where appropriate.
 */  @import "components/all";



/*
 * TRUMPS
 * Overrides, helpers, utilities and shame. The highest specificity
 * and/or classes/properties that must always 'win', using the cascade
 * to beat earlier properties.
 */  @import "trumps/all";
