/**
** catalogs styles
**/

@import "catalog-settings";
@import "catalog";
@import "catalog-content";
@import "catalog-tab";
@import "catalog-table";
@import "catalog-table-same-size";
@import "catalog-table-sticky";
@import "catalog-maps";
@import "catalog-responsive";
@import "catalog-table-footer";
@import "catalog-options-panel";
@import "catalog-checkbox-list";
@import "catalog-loader";
@import "catalog-inputs";
@import "catalog-text";
@import "catalog-icon";
@import "catalog-button";