/*
 * CATALOG TAB
 */
.catalog {

  .mat-tab-list {
    background-color: $catalog-tab-label-bg;
  }

  .mat-tab-list .mat-ink-bar {
    height: $catalog-tab-ink-bar-height;
  }

  .mat-tab-header {
    border: none;
  }

  .mat-tab-label {
    opacity: 1;
    font-size: 12px;
    padding: 0 24px;
  }

  .mat-tab-label.mat-tab-label-active {
    border: none;
  }

  .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
    color: $catalog-tab-title-active;
  }

  .mat-tab-label .mat-tab-label-content {
    color: $catalog-tab-title;
    font-weight: 700;
    text-transform: uppercase;
  }

  .mat-tab-label .mat-tab-label-content .mat-icon {
    position: absolute;
    right: 5px;
    width: 13px;
  }

  .mat-tab-label .mat-tab-label-content .mat-icon:hover {
    opacity: .8;
  }

  .mat-tab-body {
    background-color: $catalog-tab-body-color;
    color: $catalog-tab-text-color;
  }

}
