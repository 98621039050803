/**
 * GLOBAL BREAKPOINTS
 */

 $xl-breakpoint: 1560px;
 $lg-breakpoint: 1360px;
 $md-second-breakpoint: 1280px;
 $md-breakpoint: 1030px;
 $sm-breakpoint: 768px;
 $xs-breakpoint: 480px;
