/**
** Trumps: utilities, helpers and overrides. !important is often used.
**/

@import "trump.text";
@import "trump.spacing";
@import "trump.size";
@import "trump.flexbox";
@import "trump.position";
@import "trump.cursor";
@import "trump.border";
@import "trump.display";
@import "trump.overflow";
