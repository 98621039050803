/*
  styles to panel of checkbox lists 
*/

$bg-checkbox-panel:     if( $enable-light-theme, $color-gray-light-c , $color-brand-accent-900);
$bg-checkbox-option:    if( var(--enable-light-skin), $color-white, $color-brand-accent);
$width-option: 268px;

.catalog-checkbox-list {
    
    margin-top: 10px;  

    &.catalog-checkbox-list-one-column {
        max-width: $width-option;
        min-height: 230px;
        padding-top: 1px;
    }

    &.catalog-checkbox-list-one-column .mat-selection-list {
        flex-direction: column;
        max-height: 231px;
    }

    &.catalog-checkbox-list-one-column .mat-list-option {
        margin-bottom: -0.5px;
        margin-left: -1px;
        margin-top: -0.5px;
        width: 100%;
    }   

    &.catalog-checkbox-list-one-column .catalog__search {
        width: 100%;
        padding-right: 8px;
        padding-top: 3px;
    }

    &.catalog-checkbox-list-four-column {
        min-height: 234px;
        max-width: 1072px;
        padding-bottom: 3px;
        padding-top: 6px;
    }

    &.catalog-checkbox-list-four-column .mat-list-option {
        margin-left: -1px;
        margin-top: -1px;
        width: calc( 25% + 1px);
    }

    &.catalog-checkbox-list-four-column .mat-selection-list {
        max-height: 234px;
    }

    &.catalog-checkbox-list-one-column .mat-selection-list,
    &.catalog-checkbox-list-four-column {
        overflow-y: auto;
        overflow-x: hidden;  
    }

    .mat-selection-list {
        box-sizing: border-box;
        display: flex;
        padding-top: 0!important;
        width: 100%;
    }
    .mat-list-option {
        border: 1px solid $bg-checkbox-panel;
        box-sizing: border-box;
        font-size:  12px;
        height: 58.5px!important;

    }
    .mat-list-item-content {
        background-color: $bg-checkbox-option;
        height: 100%!important;
        padding: 0!important;
    }
    .mat-list-text {
        @extend .border-accent-1px;
        margin: 12px 24px;
        max-width: 164px;
        min-width: 164px;
        padding: 12px 36px!important;
        text-align: center;  
    }
    .mat-pseudo-checkbox {
        margin-left: 22px;
    }
}


.check-list-abs {
    position: absolute;
    margin-top: 0px!important;
}

.check-list-inner{
    position: relative;
    height: 30px;
    width: 300px;
}