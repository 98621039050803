/*
 * CUSTOM MATERIAL CHIP LIST
*/

.mat-chip {
  font-size: 12px;
  padding: 2px 10px 2px 13px!important;

  &.mat-primary.mat-standard-chip,
  &.mat-standard-chip {
    background-color: var(--color-brand-primary);
    color: $color-gray-dark-b;
  }

  .mat-icon {
    margin-top: 0!important;
  }

  &.mat-standard-chip .mat-chip-remove,
  .mat-icon g {
    fill: $color-white;
    opacity: 1;
  }
}

.mat-chip-list-wrapper {
  margin: -2px!important;
}

.mat-chip-list-wrapper .mat-standard-chip,
.mat-chip-list-wrapper input.mat-input-element {
  margin-top: 2px!important;
  margin-bottom: 2px!important;
}
