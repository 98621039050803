/*
 * MAIN CONTAINER STYLES
 */


.mat-sidenav-content {
  margin: 0 !important; // override the inline styles generated by the library
  padding: 0;
  width: calc(100% - #{$sidenav-default-width});
  transition: transform $sidenav-transition-time $easing-out;

  &.sidenav-is-collapsed {
    transform: translateX(-($sidenav-default-width - $sidenav-collapsed-width));
    overflow: visible;
  }
}
.mat-drawer-container {
  background-color: transparent;
  color: $color-gray-light-b;
}
