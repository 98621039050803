/*
 * COLOR PALETTES
 * brand color palletes used for the angular material theme
 */


/* palettes
 ******************************************************************************/
 $primary-color-palette: (
  50 : #fffbe7,
    100 : #fef5c4,
    200 : #feee9d,
    300 : #fee775,
    400 : #fde158,
    500 : #fddc3a,
    600 : #fdd834,
    700 : #fcd32c,
    800 : #fcce25,
    900 : #fcc518,
    A100 : #ffffff,
    A200 : #fffefa,
    A400 : #fff0c7,
    A700 : #ffeaad,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$accent-default-color-palette: (
    50 : #e6e6e6,
    100 : #c0c0c0,
    200 : #969696,
    300 : #6b6b6b,
    400 : #4c4c4c,
    500 : #2c2c2c, //main
    600 : #272727,
    700 : #212121,
    800 : #1b1b1b,
    900 : #101010,
    A100 : #ee6969,
    A200 : #e93b3b,
    A400 : #f10000,
    A700 : #d80000,
    contrast: (
        50 : #000,
        100 : #000,
        200 : #000,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000,
        A200 : #fff,
        A400 : #fff,
        A700 : #fff,
    )
);

$accent-secondary-color-palette: (
    50 : #fefefe,
    100 : #fcfcfd,
    200 : #fafafb,
    300 : #f7f8f9,
    400 : #f6f7f8,
    500 : #f4f5f7,
    600 : #f3f4f6,
    700 : #f1f2f5,
    800 : #eff0f3,
    900 : #eceef1,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$warn-color-palette: (
  50 : #ffe0e4,
  100 : #ffb3bc,
  200 : #ff808f,
  300 : #ff4d62,
  400 : #ff2641,
  500 : #ff001f, //main
  600 : #ff001b,
  700 : #ff0017,
  800 : #ff0012,
  900 : #ff000a,
  A100 : #ffffff,
  A200 : #fff2f2,
  A400 : #ffbfc0,
  A700 : #ffa6a7,
  contrast: (
      50 : #000,
      100 : #000,
      200 : #000,
      300 : #000,
      400 : #fff,
      500 : #fff,
      600 : #fff,
      700 : #fff,
      800 : #fff,
      900 : #fff,
      A100 : #000,
      A200 : #000,
      A400 : #000,
      A700 : #000,
  )
);
