/*
 * CATALOG MAPS styles
*/
.catalog {
  /******************************************* map */
  &__map {
    margin-bottom: 17px;
    margin-top: 17px;
    padding-top: 30px;
    padding-bottom: 100px;
    position: relative;
    text-align: center;
  }

  &__map img {
    min-width: 537px;
  }

  /******************************************* canvas map */
  &__canvas-map {
    height: 100%;
    min-width: 528px;
    padding: 17.5px 18.5px 18.5px 0;
    width: 100%;
  }

  &__canvas-map iframe {
    min-height: 500px;
    width: 100%;
  }

  .catalog-map-form {
    max-width: calc( 100% - 528px);
  }

  .catalog-map-form__content {
    max-height: 539px;
    overflow: auto;
    padding-right: 0;
  }
}
