/*
 * SIZE HELPER
 */



/*
************************************************************  100% sizes */
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.w-80{
  width: 80%;;
}

.w-75 {
  width: 75%;
}

.w-66 {
  width: 66%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}
/*
************************************************************  max width in percentage -%- */
.mw-40    { max-width: 40%;}
.mw-100   { max-width: 100%;}

/*
************************************************************  max width in -px- */
.mw-40px  { max-width: 40px  }
.mw-42px  { max-width: 42px  }
.mw-46px  { max-width: 46px  }
.mw-54px  { max-width: 54px;}
.mw-60px  { max-width: 60px;}
.mw-68px  { max-width: 68px;}
.mw-70px  { max-width: 70px;}
.mw-75px  { max-width: 75px;}
.mw-78px  { max-width: 78px;}
.mw-85px  { max-width: 85px }
.mw-100px { max-width: 100px;}
.mw-120px { max-width: 120px;}
.mw-140px { max-width: 140px;}
.mw-160px { max-width: 160px }
.mw-170px { max-width: 170px }
.mw-218px { max-width: 218px }
.mw-328px { max-width: 328px;}
.mw-350px { max-width: 350px;}
.mw-425px { max-width: 425px; }

/*
************************************************************  width in -px- */
.w-10px   { width: 10px!important; }
.w-15px   { width: 15px!important; }
.w-25px   { width: 25px; }
.w-26px   { width: 26px; }
.w-55px   { width: 55px; }
.w-89px   { width: 89px; }
.w-140px  { width: 140px }
.w-170px  { width: 170px }
.w-180px  { width: 180px }

/*
************************************************************  min width in percentage -%- */
.minw-100 { min-width: 100%;}

/*
************************************************************  min width in percentage -%- */
.minw-30px    { min-width: 30px; }
.minw-45px    { min-width: 45px; }
.minw-50px    { min-width: 50px!important;}
.minw-60px    { min-width: 60px;}
.minw-68px    { min-width: 68px!important;}
.minw-82px    { min-width: 82px;}
.minw-95px    { min-width: 95px;}
.minw-100px   { min-width: 100px;}
.minw-120px   { min-width: 120px;}
.minw-130px   { min-width: 130px!important;}
.minw-140px   { min-width: 140px;}
.minw-150px   { min-width: 150px;}
.minw-170px   { min-width: 170px }
.minw-185px   { min-width: 185px }
.minw-194px   { min-width: 194px }
.minw-210px   { min-width: 210px;}
.minw-218px   { min-width: 218px }
.minw-222px   { min-width: 222px;}
.minw-225px   { min-width: 225px;}
.minw-235px   { min-width: 235px }
.minw-265px   { min-width: 265px;}
.minw-305px   { min-width: 305px;}
.minw-400px   { min-width: 400px;}
.minw-440px   { min-width: 440px;}
.minw-500px   { min-width: 500px;}

/*
************************************************************  max height in -px- */
.min-height-15px  { min-height: 15px }
.max-height-36px  { max-height: 36px; }
.max-heigth-234px { max-height: 234px; }
.max-heigth-350px { max-height: 350px; }
.max-heigth-450px { max-height: 450px; }

/*
************************************************************  min height in -px- */
.min-height-100   { min-height: 100% }
.min-height-31px  { min-height: 31px  }
.min-height-36px  { min-height: 36px  }
.min-height-37px  { min-height: 37px  }
.min-height-41px  { min-height: 41px; }
.min-height-42px  { min-height: 42px; }
.min-height-86px  { min-height: 86px; }
.min-height-155px { min-height: 155px; }
.min-height-285px { min-height: 285px; }
.min-height-450px { min-height: 450px!important; }
.min-height-468px { min-height: 468px!important; }
.min-height-478px { min-height: 478px!important; }
.min-height-480px { min-height: 480px }
.min-heigth-508px { min-height: 508px; }
