/* styles of custom controls used to dates */

/******************************************* control to select a week styles */
.input-select-control .mat-icon {
  height: 8px;
  line-height: 8px!important;
}

/******************************************* control to date with specific format */
.input-datepicker-control {

  .mat-icon {
    color: var(--color-brand-primary);
  }

  .mat-form-field {
    margin: 0 1.5em;
    min-width: 100%;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
    padding: 0;
  }

  &__label {
    font-size: 12px;
    margin: 0 12px;
  }
}

.input-select-control,
.input-datepicker-control {
  display: flex;
  align-items: center;

  .mat-icon {
    cursor: pointer;
  }

  .mat-icon:hover {
    color: $color-gray-light;
  }

  input {
    color: if( var(--enable-light-skin), $tuna, $color-white);
    background-color: transparent;
    border: none;
    font-weight: bold;
    text-align: center;
  }
}
