/*
***
**  input filled styles; Ej. on business unit form To replace
    material input field
***
*/


.input-filled {
  font-size: 14px;
  max-height: 36px;
  min-height: 25px;
  padding: 11px 5px;
  text-align: center;

  &.input-filled--accent {
    background-color: $input-disabled-bg-color;
    border: 1px solid $input-disabled-border-color;
    color: $input-text-color;
  }

  &.input-filled--accent::placeholder {
    color: $color-white-60;
  }

  &.field-invalid,
  &.field-invalid::placeholder,
  &:invalid {
    color: $color-brand-warn!important;
  }

  &.field-invalid,
  &:invalid {
    border-color: $color-brand-warn;
  }
}

