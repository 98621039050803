/*
  styles to catalog loader --- Its the father of progress bar component
  than only is used on catalogs.
*/
.catalog-loader {
  top: 0px;

  &.hidden {
    visibility: hidden;
  }
}

.catalog-loader::after {
  background-color: transparent;
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 99;
}
