/*
 * CUSTOM MATERIAL RADIO BTN
 */

.mat-radio-button.mat-radio-checked
.mat-radio-outer-circle {
    border-color: var(--color-brand-primary)!important;
}

.mat-radio-button .mat-radio-inner-circle {
  background-color: var(--color-brand-primary)!important;
}

.mat-radio-button .mat-radio-label {
  max-width: 100%;
}

.mat-radio-button .mat-radio-label .mat-radio-label-content {
  font-size: 14px;
  @extend .truncate-text;
}
