/*
 * CATALOG COMPONENTS SETTINGS
 */


/* general settings
******************************************************************************/
$catalog-border:                  if( var(--enable-light-skin), $color-gray-light-c , $color-brand-accent-900);
$catalog-text:                    if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
$catalog-tab-title:               if( var(--enable-light-skin), var(--color-brand-text-accent-light-50), $color-white);
$catalog-tab-title-active:        if( var(--enable-light-skin), var(--color-brand-primary), $color-white);
$catalog-tab-label-bg:            if( var(--enable-light-skin), $color-white, $color-brand-accent-900);
$catalog-tab-border-active:       if( var(--enable-light-skin), $color-gray-theme-dark-75, transparent);

$catalog-tab-body-color:          if( var(--enable-light-skin), transparent, $color-brand-accent);
$catalog-tab-text-color:          $catalog-text;

$catalog-tab-ink-bar-height:      if( var(--enable-light-skin), 3px, 2px);

$catalog-inner-header-border:     $catalog-border;
$catalog-inner-icons:             if( var(--enable-light-skin), $color-black, $color-white);
$catalog-inner-icons-border:      if( var(--enable-light-skin), $color-gray-light-c, $color-brand-accent-900);

/* table components
******************************************************************************/
$table-font-color:                 if( var(--enable-light-skin), var(--color-brand-text-accent-light) ,$color-white);
$table-cell-border:                $catalog-border;
$table-cell-header-bottom-border:  if( var(--enable-light-skin), $color-gray-light-c ,$color-brand-accent-900);
$table-bg-headers-color:           if( var(--enable-light-skin), var(--color-brand-text-accent-light) ,$color-brand-accent); // validation not working

$table-cell-first-color:           if( var(--enable-light-skin), $color-white ,$color-gray-dark-b);
$table-cell-second-color:          if( var(--enable-light-skin), $color-primary-light ,$color-brand-accent);
$table-cell-height:                35px;

$table-small-cell-header-color:    if( var(--enable-light-skin), var(--color-brand-text-accent-light) ,$color-gray-light-b);

$table-link-color:                 var(--color-brand-primary);
$table-arrow-margin-left:          17px;

/* table footer
  ******************************************************************************/
$table-footer-font-size:           12px;
$table-footer-bg-color:            $color-gray-dark;
$table-footer-text-color:          var(--color-brand-text-accent-light);

/* inputs controls
  ******************************************************************************/
$catalog-input-label:              if( var(--enable-light-skin), var(--color-brand-text-accent-light) ,$color-white);
$catalog-input-search-color:       if( var(--enable-light-skin), var(--color-brand-text-accent-light) ,$color-white-60);

/* figure icon
******************************************************************************/
$catalog-options-panel-image-list-active: $component-figure-icon-active;

/* options panel
******************************************************************************/
$catalog-option-list-text-color:    if( var(--enable-light-skin), $color-gray-theme-dark, $color-white);
$catalog-option-panel-header-bg:    if( var(--enable-light-skin), $color-white, $color-brand-accent);
$catalog-option-panel-bg:           if( var(--enable-light-skin), var(--color-brand-accent-light), $color-brand-accent-900);

/* text
******************************************************************************/
$catalog-detail-border-color:       if( var(--enable-light-skin), var(--color-brand-accent-light), $color-brand-accent-900);

/* buttons
******************************************************************************/
$catalog-button-color:        if( var(--enable-light-skin), $color-white-60, $color-brand-accent-900);
$catalog-button-bg-color:     var(--color-brand-accent-light);