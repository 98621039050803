// styles to progress chart

.progress-chart {
    align-items: center;
    display: flex;

    &__legend {
      justify-content: center;
      display: flex;
      font-size: 10px;
      font-weight: 700;
      min-width: 207px;
      max-width: 207px;
    }

    &__legend--truncate{
      width: 110px;
    }

    .mat-progress-bar .mat-progress-bar-buffer {
      background-color: $progress-bar-empty-bg;
    }

    &.progress-chart--light-color .mat-progress-bar .mat-progress-bar-fill::after {
      background-color: $progress-bar-light-bg;
    }

    &.progress-chart--dark-color .mat-progress-bar .mat-progress-bar-fill::after {
      background-color: $progress-bar-dark-bg;
    }
}
