/**
 * bredcrumbs styles
 */


.breadcrumbs {
  align-items: center;
  display: flex;
  min-height: 45px;
  padding-left: 18px;
  padding-right: 18px;

  &__route {
    cursor: pointer;
    font-size: 11px;
  }

  &__divider {
    color:  $component-breadcrumbs-text-color;
    cursor: default;
    font-size: 12px;
    height: auto!important;
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
  }
}


a.breadcrumbs__route {
  color:  $component-breadcrumbs-text-color;
  cursor: pointer;
  text-decoration: none;

  &[href] {
    cursor: pointer;
  }
}
