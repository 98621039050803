/**
 * figure icon styles
 *
*/


.figure-icon {
  align-items: center;
  background-color: $component-figure-icon-bg;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 5px;

  .mat-icon .mat-icon-svg {
    fill: $component-figure-icon-border;
  }

  .figure-icon__bg {
    height: 30px;
    width: 45px;
  }
  .figure-icon__bg--small {
    height: 24px!important;
    width: 35px!important;
  }
}
