/*
 * USEFULL FLEXBOX HELPERS
 */

.flex,
[class*="flex-"] {
  display: flex;
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column !important;
  }

  &-align-items-center {
    align-items: center;
  }

  &-align-items-start {
    align-items: flex-start;
  }

  &-align-items-end {
    align-items: flex-end;
  }

  &-justify-content-center {
    justify-content: center!important;
  }

  &-justify-content-start {
  justify-content: flex-start;
  }

  &-justify-content-end {
    justify-content: flex-end!important;
  }

  &-justify-content-space-between {
    justify-content: space-between!important;
  }

  &-100 {
    flex: 1;
  }

  &-66 {
    flex: 1 1 66.66%;
  }

  &-80 {
    flex-basis: 80%;
  }

  &-70 {
    flex-basis: 70%;
  }

  &-60 {
    flex: 1 1 60%;
  }

  &-50 {
    flex-basis: 50%;
  }

  &-40 {
    flex: 1 1 40%;
  }

  &-33 {
    flex: 33.33%;
  }

  &-30 {
    flex-basis: 30%;
  }

  &-25 {
    flex-basis: 25%;
  }

  &-20 {
    flex-basis: 20%;
  }

  &-15 {
    flex-basis: 15%;
  }
}


.flex-completely-centered {
   align-items: center;
   justify-content: center;
 }

 .flex-completely-left {
  align-items: left;
  justify-content: left;
}

.flex-completely-right {
  align-items: flex-end;
  justify-content: right;
}