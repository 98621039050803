/*
 * CUSTOM MATERIAL COMPONENTS
 * styles for angular-material custom modified components
 */

 @import "mat.settings";
 @import "angular-material-theme";
 @import "mat.card";
//  @import "mat.field";
//  @import "mat.button";
//  @import "mat.tab";
 @import "mat.dialog";
 @import "mat.table";
//  @import "mat.select";
//  @import "mat.datepicker";
 @import "mat.checkbox";
 @import "mat.chip";
 @import "mat.button-toggle.scss";
//  @import "mat.expansion-panel";
 @import "mat.radio-button";
