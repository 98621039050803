/*
 * INNER MAIN CONTAINER
 * main content inner wrapper
 */

// imported main component settings for use on this component
@import "../../../app/main/main.component.css/all.scss";

.main-inner-wrapper {
  min-height: calc(100vh - 215px);
  position: relative;
  padding: 8px 20px 0 14px;

  .mat-card {
    background-color: transparent;
    overflow-y: auto;
  }
}

// width for inner wrapper when the sidenav is collapsed
.mat-sidenav-content.sidenav-is-collapsed .main-inner-wrapper {
  width: calc(100% + #{$sidenav-default-width - $sidenav-collapsed-width});
}

