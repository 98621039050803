/*
 * MAT DIALOG
 * base styles for material dialog components
 */

@import "../custom-dialogs/custom-dialogs-settings";

.cdk-overlay-dark-backdrop {
  background-color: $mat-dialog-overlay-bg-color;
}

// material dialog components
.mat-dialog-container {
  background-color: $dialog-background !important;
  color: $dialog-text-color;
  text-align: center;
  box-shadow: 0 0 4px 0 $mat-dialog-shadow-color;

  .mat-dialog-content {
    width: 100%;
    margin: 0;
  }

  .mat-dialog-actions {
    padding: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    justify-content: center;
    display: flex;
  }

  .mat-dialog-actions button {
    min-width: 156px;
  }

  // .mat-raised-button, .mat-stroked-button {
  //   line-height: 42px;
  //   border-radius: 22px;
  //   min-width: 180px;
  //   font-size: 19px;
  //   font-weight: bold;
  // }

  .mat-raised-button:not([class*=mat-elevation-z]),
  .mat-stroked-button:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-dialog-actions .mat-raised-button + .mat-raised-button,
  .mat-dialog-actions .mat-stroked-button + .mat-stroked-button {
    margin-left: 12px;
  }
}




