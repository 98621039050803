/*
 * panel settings
 */


/* general settings
******************************************************************************/
$panel-color:                   if(var(--enable-light-skin), $tuna, $color-white);
$panel-tab-title:               $catalog-tab-title;
$panel-tab-title-active:        $catalog-tab-title-active;
$panel-tab-label-bg:            $catalog-tab-label-bg;
$panel-tab-border-active:       $catalog-tab-border-active;

$panel-tab-body-color:          $catalog-tab-body-color;
$panel-tab-text-color:          $catalog-tab-text-color;

$panel-tab-ink-bar-height:      $catalog-tab-ink-bar-height;


$ease-in-out-curve-function:    .1s cubic-bezier(.35,0,.25,1);
$mat-tab-animation-duration:    500ms !default;
