/*
 * CATALOG TEXT
 * styles for text elements
 */


/******************************************* input label */
.catalog-input-label {
  font-size: 12px;
  line-height: 14px;
  padding-right: 16px;
}

/******************************************* link */
.catalog-link {
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-decoration: underline;
}


.catalog-input-custom-error,
.catalog-input-upload-error {
  color: $color-brand-danger;
  display: block;
  font-size: 9.75px;
  opacity: 0;
  transform: translateY(-10%);
  transition: transform 1s ease-in-out, opacity 2s ease-in-out;

  @if var(--enable-light-skin) == false {
    text-shadow: 1px 1px $color-gray-cod;
  }

  &.show-error {
    transform: translateY(0%);
    opacity: 1;
    animation: anim .3s ease-in-out;
  }

  @keyframes anim {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

/******************************************* error after upload */
.catalog-input-upload-error {
  display: none;
  &.show-error {
    display: block;
  }
}

/*******************************************  detail styles */
.catalog-detail {
  padding: 5px 7px;
  min-height: 28px;
  width: 100%;
  border: 1px solid $catalog-detail-border-color;
  min-width: 210px;
  max-width: 210px;
  @extend .wrap-text;
}
