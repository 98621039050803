/*
 * CATALOG TABLE FOOTER
 * styles for table footer elements
 */


 .catalog-table-footer {
  align-items: center;
  display: flex;

  @if var(--enable-light-skin) == false {
    background-color: $table-footer-bg-color;
  }

  /******************************************* message */
  &__message {
    font-size: $table-footer-font-size;
    margin: 0;
    padding-left: 20px;
  }

  /******************************************* pagination */
  .catalog-pagination {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin-left: auto;
    margin-right: 20px;
  }

  .catalog-pagination__item {
    margin-right: 5px;
  }

  .catalog-pagination__item .catalog-pagination__button {
    background-color: transparent!important;
    font-size: $table-footer-font-size;
    height: 35px;
    width: 35px;
  }

  .catalog-pagination__item .catalog-pagination__button .mat-button-wrapper {
    line-height: 20px!important;
  }

  .catalog-pagination__button.mat-fab.mat-accent {
    box-shadow: none!important;
  }

  @if var(--enable-light-skin) {
    .catalog-pagination__item,
    .catalog-pagination__item .catalog-pagination__button {
      color: $table-footer-text-color;
    }
  }

  .catalog-pagination__item.active .mat-fab.catalog-pagination__button,
  .catalog-pagination__item .mat-fab.catalog-pagination__button:hover {
    @if var(--enable-light-skin) {
      color: $color-white;
    }
    background-color: var(--color-brand-primary)!important;
  }

}
