.mat-dialog-container {

  // title h2
  .mat-dialog-content.confirm-dialog .mat-dialog-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  // message p
  .mat-dialog-content.confirm-dialog .mat-dialog-message {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.15;
  }

  .mat-dialog-content.confirm-dialog .mat-dialog-content {
    padding: 0 50px!important;
  }

  // custom assets components for material dialog
  .mat-dialog-content.confirm-dialog .mat-dialog-header-icon {
    background-size: 87px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 26px auto 30px;
    height: 87px;
    width: 87px;

    &.alert-icon {
      background-image: url("/assets/images/dialogs/alert_icon.svg");
    }

    &.success-icon {
      background-image: url("/assets/images/dialogs/success_icon.svg");
    }

    &.warning-icon {
      background-image: url("/assets/images/dialogs/warning_icon.svg");
    }
  }


  .mat-dialog-actions.confirm-dialog {
    margin-bottom: 19px;
  }

  .mat-dialog-actions button {
    border-width: 1px;
    border-style: solid;
  }
}
