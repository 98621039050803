/*
 * CURSOR HELPER
 */

.cursor-default {
  cursor: default!important;
}

.cursor-pointer {
  cursor: pointer!important;
}
