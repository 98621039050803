.theme-button-detail {
  @if var(--enable-light-skin) == false{
    border: none;

    &.mat-primary {
      background-color: $button-detail-dark-theme-color-primary;
    }

    &.mat-accent {
      background-color: $button-detail-dark-theme-color-accent;
      border-color: $button-detail-dark-theme-color-accent;
    }

    &.mat-warn {
      background-color: $button-detail-dark-theme-color-warn;
    }

    .mat-button-wrapper {
      color: $color-white;
    }

  } @else {

    &.mat-accent {
      border-color: $button-detail-light-theme-color-accent;
      color: $color-black;
    }

    &.mat-accent:hover .mat-button-focus-overlay {
      background-color: $button-detail-light-theme-hover-color-accent!important;
    }
  }
}
